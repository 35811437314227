import React from 'react'
import { StyleSheet, View, Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native'
import Logo from './../../../assets/Logo'
import Home from '../../../assets/Home'
import List from '../../../assets/List'
import Analytics from '../../../assets/Analytics'
import Person from '../../../assets/Person'
import Hub from '../../../assets/Hub'
import SubMenu, { SubMenuItem } from '../../SubMenu'
import Text from './../../../components/Text'
import Sliders from './../../../assets/Sliders'

const { useRef, useEffect, useState } = React

const AnalyticsButton = props => {

  const { style, highlighted } = props
  const [renderHash, setRenderHash] = useState('')
  const navigator = useNavigation()

  const buttonRef = useRef()
  const menuRef = useRef()

  const hoveredState = useRef({
    hovered: false
  }).current

  useEffect(() => {

    const onMouseOver = () => {
      hoveredState.hovered = true
      setRenderHash(Math.random())
    }

    const onMouseMove = (e) => {
      var menuButton = buttonRef.current
      if (!menuButton) return
      
      var ele = e.target
      while (true){
        if (ele == document.body)
          break;
        if (ele == menuButton){
          return
        }
        ele = ele.parentNode
      }

      if (hoveredState.hovered){
        hoveredState.hovered = false
        setRenderHash(Math.random())
      }
    }

    if (buttonRef.current)
      buttonRef.current.addEventListener('mouseover', onMouseOver)
    // TODO Add debounce on this
    document.body.addEventListener('mousemove', onMouseMove)
    

    return () => {
      if (buttonRef.current)
        buttonRef.current.removeEventListener('mouseover', onMouseOver)
      document.body.removeEventListener('mousemove', onMouseMove)
    }

  }, [])

  const onNavigateToGoogleConversionTracking = () => {
    navigator.navigate('GoogleConversionTracking')
  }

  const onNavigateToEventValues = () => {
    navigator.navigate('EventValues')
  }

  const hovered = hoveredState.hovered

  return <View 
    style={[
      styles.buttonContainer,
      style,
    ]}
    ref={buttonRef}
  >
    <Analytics 
      highlighted={highlighted}
    />
    <View
      ref={menuRef}
      style={styles.menuContainer}
    >
      {hovered ? <SubMenu>
        <SubMenuItem
          icon={<Hub />}
          onPress={onNavigateToEventValues}
        >
          <Text>Event values</Text>
        </SubMenuItem>
        <SubMenuItem
          icon={<Sliders />}
          onPress={onNavigateToGoogleConversionTracking}
        >
          <Text>Google conversion tracking</Text>
        </SubMenuItem>
      </SubMenu> : null}
    </View>
  </View>
}


const styles = StyleSheet.create({
  buttonContainer: {
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: 90,
    top: 0,
  },
})

export default AnalyticsButton