import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 20,
    zIndex: 100,
  },
  error: {},
  text: {
    fontSize: 18,
    color: '#212529',
    fontWeight: 'bold',
  },
  iconContainer: {
    marginRight: 18,
  },
  closeIconContainer: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
})
