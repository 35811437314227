import React from 'react'
import { StyleSheet, View, Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native'
import Person from '../../../assets/Person'
import Exit from '../../../assets/Exit'
import SubMenu, { SubMenuItem } from '../../SubMenu'
import Text from './../../../components/Text'
import Sliders from './../../../assets/Sliders'
import { useSessionContext } from './../../../util/Session'

const { useRef, useEffect, useState } = React

const ProfileButton = props => {

  const { style, highlighted } = props
  const [renderHash, setRenderHash] = useState('')
  const navigator = useNavigation()
  const { clearAuthToken } = useSessionContext()

  const buttonRef = useRef()
  const menuRef = useRef()

  const hoveredState = useRef({
    hovered: false
  }).current

  useEffect(() => {

    const onMouseOver = () => {
      hoveredState.hovered = true
      setRenderHash(Math.random())
    }

    const onMouseMove = (e) => {
      var menuButton = buttonRef.current
      if (!menuButton) return
      
      var ele = e.target
      while (true){
        if (ele == document.body)
          break;
        if (ele == menuButton){
          return
        }
        ele = ele.parentNode
      }

      if (hoveredState.hovered){
        hoveredState.hovered = false
        setRenderHash(Math.random())
      }
    }

    if (buttonRef.current)
      buttonRef.current.addEventListener('mouseover', onMouseOver)
    // TODO Add debounce on this
    document.body.addEventListener('mousemove', onMouseMove)
    

    return () => {
      if (buttonRef.current)
        buttonRef.current.removeEventListener('mouseover', onMouseOver)
      document.body.removeEventListener('mousemove', onMouseMove)
    }

  }, [])

  const onNavigateToProfile = () => {
    navigator.navigate('Profile')
  }

  const onLogout = () => {
    clearAuthToken()
  }

  const hovered = hoveredState.hovered

  return <View 
    style={[
      styles.buttonContainer,
      style,
    ]}
    ref={buttonRef}
  >
    <Person highlighted={highlighted} />
    <View
      ref={menuRef}
      style={styles.menuContainer}
    >
      {hovered ? <SubMenu>
        <SubMenuItem
          icon={<Sliders />}
          onPress={onNavigateToProfile}
        >
          <Text>Profile</Text>
        </SubMenuItem>
        <SubMenuItem
          icon={<Exit />}
          onPress={onLogout}
        >
          <Text>Logout</Text>
        </SubMenuItem>
      </SubMenu> : null}
    </View>
  </View>
}


const styles = StyleSheet.create({
  buttonContainer: {
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: 90,
    bottom: 0,
  },
})

export default ProfileButton