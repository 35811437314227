import React from 'react'
import Svg, { Path } from 'react-native-svg'

const Add = props => {

  return <Svg 
    width="14" 
    height="14" 
    viewBox="0 0 14 14" 
    fill="none"
    {...props}
  >
    <Path 
      d="M7.50001 8.69992H8.50001V6.49992H10.7V5.49992H8.50001V3.29992H7.50001V5.49992H5.30001V6.49992H7.50001V8.69992ZM3.33334 11.6666C3.06668 11.6666 2.83334 11.5666 2.63334 11.3666C2.43334 11.1666 2.33334 10.9333 2.33334 10.6666V1.33325C2.33334 1.06659 2.43334 0.833252 2.63334 0.633252C2.83334 0.433252 3.06668 0.333252 3.33334 0.333252H12.6667C12.9333 0.333252 13.1667 0.433252 13.3667 0.633252C13.5667 0.833252 13.6667 1.06659 13.6667 1.33325V10.6666C13.6667 10.9333 13.5667 11.1666 13.3667 11.3666C13.1667 11.5666 12.9333 11.6666 12.6667 11.6666H3.33334ZM3.33334 10.6666H12.6667V1.33325H3.33334V10.6666ZM3.33334 1.33325V10.6666V1.33325ZM1.33334 13.6666C1.06668 13.6666 0.833344 13.5666 0.633344 13.3666C0.433344 13.1666 0.333344 12.9333 0.333344 12.6666V2.33325H1.33334V12.6666H11.6667V13.6666H1.33334Z" 
      fill="#919696"
    />
  </Svg>
}

export default Add
