import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import Text from './../Text'

const Button = props => {

  const { onPress, title, style, primary } = props

  return <Pressable 
    style={[
      styles.button, 
      primary ? styles.buttonPrimary : undefined,
      style,
    ]}
    onPress={onPress}
  >
    <Text style={styles.buttonText}>{title}</Text>
  </Pressable>
}

export default Button

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'white',
    minWidth: 122,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: 10,
    borderWidth: 1,
    borderColor: '#D9DEE3',
  },
  buttonPrimary: {
    backgroundColor: '#FFB800',
    borderWidth: 0,
  },
  buttonText: {
    fontSize: 16,
    lineHeight: 22,
    fontFamily: 'Roboto_500Medium',
  },
})
