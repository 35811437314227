import React from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Logo from './../../../assets/Logo'
import Home from '../../../assets/Home'
import List from '../../../assets/List'
import Analytics from '../../../assets/Analytics'
import Person from '../../../assets/Person'
import Hub from '../../../assets/Hub'
import SubMenu, { SubMenuItem } from '../../SubMenu'
import Text from './../../../components/Text'
import Sliders from './../../../assets/Sliders'
import AnalyticsButton from './AnalyticsButton'
import ProfileButton from './ProfileButton'

const Navbar = props => {

  const navigation = useNavigation()
  const { 
    selectedRouteName, 
    onSelectPage,
  } = props

  const goHome = () => navigation.navigate('CustomerList', { 'gt': 0 })

  return <View style={styles.container}>
    <Pressable 
      style={styles.iconContainer}
      onPress={goHome}
    >
      <Logo />
    </Pressable>
    <View>
      <Pressable 
        style={styles.iconContainer}
        onPress={goHome}
      >
        <Home highlighted={selectedRouteName == 'CustomerList'} />
      </Pressable>
      <Pressable 
        style={styles.iconContainer}
        onPress={() => onSelectPage('EventLogs')}
      >
        <List highlighted={selectedRouteName == 'EventLogs'} />
      </Pressable>
      <AnalyticsButton
        style={styles.iconContainer}
        highlighted={
          ['EventValues', 'GoogleConversionTracking'].includes(selectedRouteName)
        }
      />
    </View>
    <ProfileButton
      style={styles.iconContainer}
      highlighted={
          ['Profile'].includes(selectedRouteName)
        }
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    width: 90,
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    borderRightWidth: 1,
    borderRightColor: '#F0F0F0',
    zIndex: 99,
  },
  iconContainer: {
    width: 90,
    height: 90,
    alignItems: 'center',
    justifyContent: 'center',
  },
  analyticsButtonContainer: {
    position: 'relative',
  },
  analyticsMenu: {
    position: 'absolute',
    left: 90,
    top: 0,
  },
})

export default Navbar
