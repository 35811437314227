import React from 'react'
import { View } from 'react-native'
import Text from './../../../components/Text'

const MagicLinkView = props => {

  const { isInvalid } = props

  return <View>
    {isInvalid ? <Text>Unable to verify link</Text> : null}
    {isInvalid ? null : <Text>Verifying...</Text>}
  </View>
}

export default MagicLinkView
