import React from 'react'
import { View, StyleSheet } from 'react-native'
import Text from './../Text'

const Cell = (props) => {
  const { style, data } = props
  return (
    <View style={[styles.cell, style]}>
      {typeof data === 'string' ? (
        <Text style={[styles.cellText, props.textStyle]}>{data}</Text>
      ) : (
        data
      )}
    </View>
  )
}

export default Cell

const styles = StyleSheet.create({
  cell: {
    flex: 1,
    //alignSelf: 'stretch',
    textAlign: 'left',
    //alignItems: 'center',
    //justifyContent: 'center',
    paddingHorizontal: 8,
  },
  cellText: {
    color: '#212529',
    fontSize: 16,
    lineHeight: 22,
  },
})