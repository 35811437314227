/**
 * @fileoverview Provides utility to hold an array of app notifications and to
 * add new ones.
 */
import * as React from 'react'
import Toast from './../components/Toast'

const { useRef, useState, Fragment, useContext, createContext } = React

const Context = createContext({});
export const useAppNotificationAndErrorsContext = () => useContext(Context);

const AppNotificationsAndErrors = (props) => {
  const { children, ...otherProps } = props
  var notifications = useRef([]).current
  var [notificationsState, setNotificationsState] = useState([])

  const pushNotification = (notif) => {
    /* eslint-disable-next-line */
    console.log(notif)
    const newNotif = {
      ...notif,
      // Give React something it can use for a key to keep the UI consistent
      uniqueKey: (Math.random() * 99999999).toString(36).slice(0, 6),
    }
    notifications.push(newNotif)
    setNotificationsState([...notifications])
    // Auto dismiss the notification after 10 seconds
    setTimeout(() => onNotificationDismiss(newNotif), 10000)
  }

  const onNotificationDismiss = (notif) => {
    var index = notifications.findIndex((o) => o == notif)
    if (index == -1) {
      // This will happen when the notification was manually dismissed, the
      // auto dismiss will still fire
      return
    }
    notifications.splice(index, 1)
    setNotificationsState([...notifications])
  }

  var child = React.cloneElement(children, {
    ...otherProps,
  })


  return (
    <Context.Provider
      value={{
        pushNotification,
      }}
    >
      <Fragment>
        {child}
        <Toast
          notifications={notificationsState}
          onNotificationDismiss={({ item }) => onNotificationDismiss(item)}
        />
      </Fragment>
    </Context.Provider>
  )
}

export default AppNotificationsAndErrors
