import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

const SmallSquareButton = props => {
  const { children, style, ...otherProps } = props

  return <Pressable style={[styles.container, style]} {...otherProps}>
    {children}
  </Pressable>
}

export default SmallSquareButton

const styles = StyleSheet.create({
  container: {
    borderRadius: 3,
    width: 34,
    height: 34,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#D9DEE3',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

