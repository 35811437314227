import React from 'react'
import { View, StyleSheet } from 'react-native'
import Text from './../../../components/Text'

const EventJSONPopupContent = props => {

  const { eventJSON } = props

  return <View style={styles.container}>
    <Text style={styles.text}>
      {eventJSON}
    </Text>
  </View>
}

export default EventJSONPopupContent

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  text: {
    wordBreak: 'break-all',
    fontFamily: 'monospace',
  },
})

