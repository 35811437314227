import React from 'react'
import { View, StyleSheet } from 'react-native'
import DoubleArrow from './../../assets/DoubleArrow'
import BackArrow from '../../assets/BackArrow'
import Button from './Button'
import Text from './../Text'


const Pagination = props => {

  const {
    currentPageNo = 1,
    onNext,
    hasNextPage,
    onPrev,
    onGoToPage,
  } = props

  var eles = []
  for (var i = 1; i <= currentPageNo; i++){
    let i2 = i
    eles.push(<Button 
      selected={i==currentPageNo}
      onPress={onGoToPage ? () => onGoToPage(i2) : undefined}
    >
      <Text>{i}</Text>
    </Button>)
  }

  return <View style={styles.container}>
    {/*(onGoToPage && currentPageNo > 1) ? <Button 
      onPress={() => onGoToPage(1)}
    >
      <DoubleArrow />
    </Button> : null*/}
    {/*onPrev ? <Button 
      onPress={onPrev}
      disabled={currentPageNo == 1}
    >
      <BackArrow />
    </Button> : null*/}
    {eles}
    {hasNextPage ? <Button onPress={onNext}>
      <BackArrow style={{ 'transform': 'rotate(180deg)', }} />
    </Button> : null}
    {/*<Button>
      <DoubleArrow style={{ 'transform': 'rotate(180deg)', }} />
    </Button>*/}
  </View>
}

export default Pagination

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})
