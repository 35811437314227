import React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'

const NavbarContainer = props => {

  const { selectedRouteName } = props
  const navigation = useNavigation()


  const onSelectPage = (page) => {
    navigation.navigate(page)
  }

  return <View 
    onSelectPage={onSelectPage}
    selectedRouteName={selectedRouteName}
  />
}

export default NavbarContainer
