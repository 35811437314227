import * as React from 'react'
import Svg, { Rect, Circle } from 'react-native-svg'

const Exclamation = (props) => (
  <Svg width={4} height={18} viewBox="0 0 4 18" fill="none" {...props}>
    <Rect
      x={4}
      y={12}
      width={4}
      height={12}
      rx={2}
      transform="rotate(-180 4 12)"
      fill="white"
    />
    <Circle cx={2} cy={16} r={2} transform="rotate(-180 2 16)" fill="white" />
  </Svg>
)

export default Exclamation
