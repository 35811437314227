import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const BackArrow = ({ color = 'black', ...props }) => (
  <Svg width={16} height={28} viewBox="0 0 16 28" fill="none" {...props}>
    <Path
      d="M14.9991 1L1.47194 13.259C1.03396 13.6559 1.03396 14.3441 1.47194 14.741L14.9991 27"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default BackArrow
