/**
 * Given a iso date with a timezone, this will simply drop the timezone off the
 * end so that if you instanciate it with `new Date(dateStr)` it won't do any
 * timezone manipulation and you'll have a date object that's local to that
 * timezone.
 * @returns {string}
 */
export function dropTimeZoneFromDate(isoDateStr) {
  if (!isoDateStr) throw new Error(`Received a falsy value: ${isoDateStr}`)

  var match = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?([+-]{1}\d{2}:\d{2})/.test(
    isoDateStr
  )

  if (!match)
    throw new Error(
      `Expected isoDateStr '${isoDateStr}' to have a timezone offset.`
    )

  return isoDateStr.slice(0, -6)
}

/**
 * Get an easily readable description of how much time needs to pass to reach
 * a scheduled start time.
 */
export function getTimeToDescription(props) {
  const { now, start, end } = props

  if (start < now && end > now) {
    return 'now'
  } else if (start > now) {
    var timePassed = start - now

    if (timePassed < 1500) {
      return 'in 1 second'
    } else if (timePassed < 1000 * 60) {
      var seconds = Math.round(timePassed / 1000)
      return `in ${seconds} seconds`
    } else if (timePassed < 1000 * 90) {
      return `in 1 minute`
    } else if (timePassed < 1000 * 60 * 59.5) {
      var minutes = Math.round(timePassed / (1000 * 60))
      return `in ${minutes} minutes`
    } else if (timePassed < 1000 * 60 * 90) {
      return `in 1 hour`
    } else if (timePassed < 1000 * 60 * 60 * 23.5) {
      var hours = Math.round(timePassed / (1000 * 60 * 60))
      return `in ${hours} hours`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 1.5) {
      return `in 1 day`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 7) {
      var days = Math.round(timePassed / (1000 * 60 * 60 * 24))
      return `in ${days} days`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 7 * 1.5) {
      return `in 1 week`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 7 * 4) {
      var weeks = Math.round(timePassed / (1000 * 60 * 60 * 24 * 7))
      return `in ${weeks} weeks`
    } else {
      return null
    }
  } else {
    // Inverting changes the rounding up rules, but doesn't really matter
    var timePassed = (end - now) * -1

    if (timePassed < 1500) {
      return '1 second ago'
    } else if (timePassed < 1000 * 55) {
      var seconds = Math.round(timePassed / 1000)
      return `${seconds} seconds ago`
    } else if (timePassed < 1000 * 90) {
      return `1 minute ago`
    } else if (timePassed < 1000 * 60 * 59.5) {
      var minutes = Math.round(timePassed / (1000 * 60))
      return `${minutes} minutes ago`
    } else if (timePassed < 1000 * 60 * 90) {
      return `1 hour ago`
    } else if (timePassed < 1000 * 60 * 60 * 23.5) {
      var hours = Math.round(timePassed / (1000 * 60 * 60))
      return `${hours} hours ago`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 1.5) {
      return `1 day ago`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 7) {
      var days = Math.round(timePassed / (1000 * 60 * 60 * 24))
      return `${days} days ago`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 7 * 1.5) {
      return `1 week ago`
    } else if (timePassed < 1000 * 60 * 60 * 24 * 7 * 4) {
      var weeks = Math.round(timePassed / (1000 * 60 * 60 * 24 * 7))
      return `${weeks} weeks ago`
    } else {
      return null
    }
  }
}

/**
 * Gets a date time description that makes sense relative to today.
 */
export const getRelativeDateTime = function (props) {
  const { now, date } = props

  var nowDate = safeNewDate(now)
  var dateDate = safeNewDate(date)

  var hours = dateDate.getHours()
  if (hours >= 12) hours -= 12
  var timePart =
    hours + ':' + padZero(dateDate.getMinutes()) + getMeridian(dateDate)
  var datePart =
    padZero(dateDate.getDate()) + ' ' + getShortMonthStr(dateDate.getMonth())

  if (isSameDay(nowDate, dateDate)) {
    return timePart
  }
  if (nowDate.getFullYear() == dateDate.getFullYear()) {
    return datePart + ' ' + timePart
  }
  return datePart + ' ' + dateDate.getFullYear() + ' ' + timePart
}

/**
 * @param {Date} d1
 * @param {Date} d2
 */
const isSameDay = function (d1, d2) {
  return (
    d1.getFullYear() == d2.getFullYear() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getDate() == d2.getDate()
  )
}

const getMeridian = function (date) {
  if (date.getHours() < 12) return 'am'
  return 'pm'
}

export const padZero = function (str) {
  str = String(str)
  while (str.length < 2) str = '0' + str
  return str
}

const getShortMonthStr = function (monthInt) {
  switch (monthInt) {
    case 0:
      return 'Jan'
    case 1:
      return 'Feb'
    case 2:
      return 'Mar'
    case 3:
      return 'Apr'
    case 4:
      return 'May'
    case 5:
      return 'Jun'
    case 6:
      return 'Jul'
    case 7:
      return 'Aug'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dec'
    default:
      throw new Error('Invalid month integer.')
  }
}

/**
 * @param {Date} d
 */
export const formatToLocalDateString = (d) => {
  if (d instanceof Date == false || isNaN(d))
    throw new Error('Date is invalid.')

  return (
    d.getFullYear() +
    '-' +
    padZero(d.getMonth() + 1) +
    '-' +
    padZero(d.getDate()) +
    ' ' +
    padZero(d.getHours()) +
    ':' +
    padZero(d.getMinutes()) +
    ':' +
    padZero(d.getSeconds()) +
    '.' +
    padZeroFromEnd(d.getMilliseconds(), 3)
  )
}

function padZeroFromEnd(str, length = 2) {
  str = String(str)
  while (str.length < length) {
    str = str + '0'
  }
  return str
}

/**
 * Gets the date with 'st', 'th', etc appended.
 * @param {number} 1 to 31
 * @returns {string}
 */
export const getDescriptiveDate = (date) => {
  switch (date) {
    case 1:
    case 21:
    case 31:
      return date + 'st'
    case 2:
    case 22:
      return date + 'nd'
    case 3:
    case 23:
      return date + 'rd'
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
      return date + 'th'
    default:
      throw new Error('Invalid date')
  }
}

/**
 * Gets the short month name of a date e.e. Jan, Feb, Mar
 * @param {number} 0 to 11
 * @returns {string}
 */
export const getMonthStr = (monthInt) => {
  switch (monthInt) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
    default:
      throw new Error('Invalid month')
  }
}

/**
 * Instantiates a date Date object, used because doing the following doesn't
 * work in React-Native but does work in the browser.
 * ```
 * // Replace this with
 * var date = new Date('2020-08-11 21:23:00')
 * // with
 * var date = safeNewDate('2020-08-11 21:23:00')
 * ```
 * @param {string} date
 * @returns {Date}
 */
export const safeNewDate = function (date) {
  if (typeof date !== 'string') return new Date(date)

  var match = date.match(
    /(\d{4})-(\d{2})-(\d{2})[\sT](\d{2}):(\d{2}):(\d{2})(.(\d+))?/
  )
  if (!match) throw new Error('Invalid format.')

  var [, year, month, date, hours, minutes, seconds, , millseconds] = match

  return new Date(
    year,
    Number(month) - 1,
    date,
    hours,
    minutes,
    seconds,
    millseconds || 0
  )
}
