import * as React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'
//import { decodeJWT } from './../../App/Auth'
import { useSessionContext } from './../../../util/Session'
import { useRequestsContext } from './../../../util/Requests'

const { useEffect, useState } = React

const LoginVerificationContainer = (props) => {

  const navigation = useNavigation()
  const { setAuthToken } = useSessionContext()
  const { graphQLAuthCall } = useRequestsContext()
  const [verifying, setVerifying] = useState(false)
  const [codeChars, setCodeChars] = useState([])

  useEffect(() => {
    ;(async function(){
      // Then poll for authentication
      do {
        var authToken = localStorage.getItem('authToken')
        if (authToken){
          setAuthToken(authToken)
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000))
      } while(true)
    })()
  }, [])

  const onVerifyCodeChange = async (codeChars) => {
    setCodeChars(codeChars)
    var code = codeChars.join('')
    if (!code || code.length != 6) return
    try {
      setVerifying(true)
      var { result, success } = await graphQLAuthCall(
        `
          mutation($input: LoginWithCodeInput!) {
            loginWithCode(input: $input){
              jwt
            }
          }
        `,
        {
          input: {
            code,
          },
        }
      )
    } catch (err) {
      setVerifying(false)
      throw err
    }
    if (!success) {
      setVerifying(false)
      return
    }
    var token = result.data.loginWithCode.jwt
    setAuthToken(token)
    
  }

  const onGoBack = () => navigation.goBack()

  return (
    <View
      onVerifyCodeChange={onVerifyCodeChange}
      verifying={verifying}
      codeChars={codeChars}
      onGoBack={onGoBack}
    />
  )
}

export default LoginVerificationContainer
