import React from 'react'
import { View, Platform } from 'react-native'
import Header from './../../../components/Header'
import Text from './../../../components/Text'
import SquareButton from './../../../components/SmallSquareButton'
import VerifyCodeInput from './../../../components/VerifyCodeInput'
import BackArrow from '../../../assets/BackArrow2'
import styles from './styles'

const LoginVerification = (props) => {
  const { onVerifyCodeChange, verifying, codeChars, onGoBack } = props
  return (
    <View style={[styles.container]}>
      {Platform.OS == 'ios' ? (
        <SquareButton
          style={{ position: 'absolute', top: 50, left: 20 }}
          onPress={onGoBack}
        >
          <BackArrow />
        </SquareButton>
      ) : null}
      <Header>Email sent</Header>
      <Text>
        A sign in link has been sent to you via email. Tap it to continue.
      </Text>
      <Text style={{ alignSelf: 'flex-start', marginTop: 20 }}>
        Or enter the code below.
      </Text>
      <View style={styles.codeContainer}>
        {verifying ? (
          <Text>Verifying...</Text>
        ) : (
          <VerifyCodeInput
            onChangeValue={onVerifyCodeChange}
            value={codeChars}
          />
        )}
      </View>
    </View>
  )
}

export default LoginVerification
