import React from 'react'
import { View, StyleSheet } from 'react-native'
import Text from './../Text'
import Header from '../Header'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { 
      hasError: true, 
      message: error.message,
      stack: error.stack,
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <View style={styles.container}>
        <Header style={styles.header}>Application error</Header>
        <Text style={styles.text}>{this.state.message}</Text>
        <Text>
          {this.state.stack}
        </Text>
      </View>
    }

    return this.props.children; 
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 56,
  },
  header: {
    color: 'red',
  },
})

export default ErrorBoundary
