import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    maxWidth: 500,
    alignSelf: 'center',
    position: 'relative',
  },
  codeContainer: {
    height: 80,
    justifyContent: 'center',
  },
})

export default styles
