import React from 'react'
import { StyleSheet, Pressable } from 'react-native'
import Header from './../../../components/Header'
import Table from './../../../components/Table'
import Text from './../../../components/Text'
import { formatToReadableCurrency } from './../../../util/formatting'
import { safeNewDate, formatToLocalDateString } from './../../../util/date'
import ValueFilter from './ValueFilter'
import { countriesHash } from './../../../util/countryCodes'

const CustomerListView = props => {
  
  const { 
    users, 
    onCustomerSelect, 
    onNextPage,
    hasNextPage,
    currentPageNo,
    onGoToPage,
    greaterThanValue,
    onGreaterThanValueChange,
  } = props

  const formatToRow = dto => {

    var createdAt = formatToLocalDateString(safeNewDate(dto.createdAt))
    if (dto.lastEventTimestamp){
      var lastEventDate = formatToLocalDateString(safeNewDate(dto.lastEventTimestamp)).slice(0, -4)
    } else {
      var lastEventDate = ''
    }
    var countryData = countriesHash[dto.countryCode]

    return [

      dto.id.slice(0, 8),
      dto.email,
      dto.name,
      //dto.company,
      <Pressable 
        style={styles.countryContainer}
        onPress={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {countryData ? 
          <Text style={styles.flagContainer}>
            <span title={countryData.name}>{countryData.emoji}</span>
          </Text> : 
          null}
        <Text>{dto.ip}</Text>
      </Pressable>,
      formatToReadableCurrency(dto.value / 100),
      //createdAt.slice(0, -4),
      lastEventDate,
    ]
  }

  const buildRowHref = (dto) => {
    return `/client/${dto.id}`
  }

  return <>
    <Header style={styles.header}>Customer List</Header>

    <Table
      header={
        [
          'customer #id',
          'email',
          'name',
          'country',
          'value',
          //'created at',
          'last active',
        ]
      }
      data={
        users.map(formatToRow)
      }
      rowLinks={users.map(buildRowHref)}
      filters={[
        null,
        null,
        null,
        null,
        {
          filterUI: <ValueFilter 
            greaterThanValue={greaterThanValue}
            onGreaterThanValueChange={onGreaterThanValueChange}
          />
        }
      ]}
      //getCellStyle={getCellStyle}
      //onEndReached={onEndReached}
      //renderHash={renderHash}
      //keyExtractor={keyExtractor}
      //style={style}
      onRowPress={onCustomerSelect}
      /*onRowPress={
        onRowPress
          ? (index) => {
              onRowPress(paginationRef.tableData[index])
            }
          : undefined
      }
      onRefresh={refreshData}
      ListEmptyComponent={
        (paginationRef.fetching === undefined || paginationRef.fetching) &&
        paginationRef.tableData.length == 0
          ? ListLoadingComponent
          : ListEmptyComponent
      }
      rowStyle={rowStyle}*/
      onNextPage={onNextPage}
      hasNextPage={hasNextPage}
      currentPageNo={currentPageNo}
      onGoToPage={onGoToPage}
      buildRowHref={buildRowHref}
    />
  </>
}

export default CustomerListView

const styles = StyleSheet.create({
  header: {
    marginBottom: 34,
  },
  countryContainer: {
    flexDirection: 'row',
    pointerEvents: 'none',
  },
  flagContainer: {
    paddingRight: 5,
  },
})