import React from 'react'
import { StyleSheet } from 'react-native'
import Text from './../Text'

const Header = (props) => {
  const { children, style } = props
  return <Text style={[styles.header, style]}>
    {children}
  </Text>
}

export default Header

const styles = StyleSheet.create({
  header: {
    fontWeight: 'bold',
    fontSize: 26,
    lineHeight: '34px',
  },
})
