import * as React from 'react'
import TextInput from './../TextInput'

const NumberInput = (props) => {
  return (
    <TextInput
      keyboardType="number-pad"
      onKeyPress={(e) => {
        if (
          // Skip if Backspace, Enter, etc
          e.nativeEvent.key.length == 1 &&
          /^[0-9.]$/.test(e.nativeEvent.key) == false
        ) {
          e.preventDefault()
          return
        }
      }}
      {...props}
    />
  )
}

export default NumberInput
