import React from 'react'
import Svg, { Path, Circle } from 'react-native-svg'

const Logo = props => {
  return <Svg 
    width="42" 
    height="42" 
    viewBox="0 0 42 42" 
    fill="none"
    {...props}
  >
    <Path 
      d="M32.7722 25.0001C32.7722 33.711 25.7107 40.7725 17 40.7725C8.28925 40.7725 1.22778 33.711 1.22778 25.0001C1.22778 16.2893 8.28925 9.22778 17 9.22778C25.7107 9.22778 32.7722 16.2893 32.7722 25.0001Z" 
      stroke="black" 
      strokeWidth="2.45556"
    />
    <Circle cx="38" cy="4" r="4" transform="rotate(180 38 4)" fill="black" />
  </Svg>

}

export default Logo
