/**
 * @fileoverview Render the children but any time the user clicks outside
 * of the children the 'onClose' callback will fire.
 */
import React from 'react'
import { View } from 'react-native'

const { useEffect, useRef } = React

const ContextPopup = props => {

  const { onClose, children, ...otherProps } = props

  const popupContainerRef = useRef()

  useEffect(() => {

    const onGlobalTap = (e) => {
      var popupContainer = popupContainerRef.current
      var ele = e.target
      while (true){
        if (ele == document.body)
          break;
        if (ele == popupContainer){
          // Don't close a tap on the popup container
          return
        }
        ele = ele.parentNode
      }
      onClose(e)
    }

    document.body.addEventListener('click', onGlobalTap, true)
    return () => {
      document.body.removeEventListener('click', onGlobalTap, true)
    }
  }, [])

  return <View ref={popupContainerRef} {...otherProps}>
    {children}
  </View>
}

export default ContextPopup