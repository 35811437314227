import React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'
import { useRequestsContext } from './../../../util/Requests'

const { useState, useEffect, useRef } = React

const EventLogsContainer = props => {

  const { userId, title } = props
  const navigation = useNavigation()
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [events, setEvents] = useState([])
  const [afterCursor, setAfterCursor] = useState(undefined)
  const { graphQLCall } = useRequestsContext()
  const paginationRef = useRef({
    cursors: []
  }).current
  const [hasNextPage, setHasNextPage] = useState(false)

  const RECORDS_PER_PAGE = 50

  useEffect(async () => {
    var response = await graphQLCall(
      `
        query(
          $first: Int!,
          $after: String,
          $timestampStart: BigInt,
          $timestampEnd: BigInt,
          $userId: ID
        ) {
          events(
            first: $first, 
            after: $after,
            timestampStart: $timestampStart,
            timestampEnd: $timestampEnd,
            userId: $userId,
          ) {
            nodes {
              id
              userEvent
              ip
              timestamp
              userId
              countryCode
            }
            totalCount
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `,
      {
        first: RECORDS_PER_PAGE,
        after: afterCursor,
        timestampStart: fromDate ? fromDate.valueOf() : undefined,
        timestampEnd: toDate ? toDate.valueOf() : undefined,
        userId,
      }
    );
    const { success, result } = response
    if (!success)
      return

    const events = result.data.events.nodes
    paginationRef.cursors.push(result.data.events.pageInfo.endCursor)
    setEvents(events)
    setHasNextPage(result.data.events.pageInfo.hasNextPage)
    
    /*setPaginationObj({
      pageNum: 1,
      totalPages: Math.ceil(result.data.events.totalCount / RECORDS_PER_PAGE),
    })*/
  }, [afterCursor, fromDate, toDate])

  const onEventSelect = index => {
    var event = events[index]
    navigation.navigate('Client', {
      id: event.userId,
    })
  }

  const onNextPage = () => {
    if (!paginationRef.cursors.length)
      return
    var lastCursor = paginationRef.cursors[paginationRef.cursors.length - 1]
    setAfterCursor(lastCursor)
  }

  const onPrevPage = () => {
    if (paginationRef.cursors.length <= 1)
      return
    paginationRef.cursors.pop()
    paginationRef.cursors.pop()
    var lastCursor = paginationRef.cursors[paginationRef.cursors.length - 1]
    setAfterCursor(lastCursor)
  }

  const onFromDateChange = (date) => {
    paginationRef.cursors = []
    setFromDate(date)
  }

  const onToDateChange = (date) => {
    paginationRef.cursors = []
    setToDate(date)
  }

  const onGoToPage = (pageNum) => {
    paginationRef.cursors = paginationRef.cursors.slice(0, (pageNum - 1))
    var lastCursor = paginationRef.cursors[paginationRef.cursors.length - 1]
    setAfterCursor(lastCursor)
  }


  return <View
    fromDate={fromDate}
    onFromDateChange={onFromDateChange}
    toDate={toDate}
    onToDateChange={onToDateChange}
    onEventSelect={onEventSelect}
    events={events}
    onNextPage={onNextPage}
    hasNextPage={hasNextPage}
    onPrevPage={onPrevPage}
    currentPageNo={paginationRef.cursors.length}
    onGoToPage={onGoToPage}
    title={title}
  />
}

export default EventLogsContainer
