import React from 'react'
import { StyleSheet } from 'react-native'
import Text from './../Text'

const Label = props => {
  const { children, style } = props
  return <Text style={[styles.label, style]}>
    {children}
  </Text>
}

export default Label

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    lineHeight: '22px',
    color: '#919696',
  },
})
