import React from 'react'
import Svg, { Path } from 'react-native-svg'

const Funnel = props => {
  return <Svg 
    width="12" 
    height="12" 
    viewBox="0 0 12 12" 
    fill="none"
    {...props}
  >
    <Path 
      d="M1 3V1H11V3L7 7V11H5V7L1 3Z" 
      stroke="#B4BDC6" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </Svg>
}

export default Funnel