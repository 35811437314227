import React from 'react'
import { View, StyleSheet } from 'react-native'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Button from './../../../components/Button'
import Text from './../../../components/Text'
import Label from './../../../components/Label'

const { useState } = React

const DateFilters = props => {

  const {
    fromDate: fromDateParent,
    onFromDateChange,
    toDate: toDateParent,
    onToDateChange,
    onClose,
  } = props

  const [fromDate, setFromDate] = useState(fromDateParent)
  const [toDate, setToDate] = useState(toDateParent)

  const onConfirm = () => {
    onFromDateChange(fromDate)
    onToDateChange(toDate)
    onClose()
  }

  return <View style={styles.dateFilterContainer}>
    <Label style={{marginBottom: 10}}>From</Label>
    <DatePicker
      selected={fromDate}
      onChange={setFromDate}
      showTimeSelect
      dateFormat="MMMM d, yyyy h:mm aa"
      inline
    />
    <View style={styles.rule} />
    <Label style={{marginBottom: 10}}>To</Label>
    <DatePicker
      selected={toDate}
      onChange={setToDate}
      showTimeSelect
      dateFormat="MMMM d, yyyy h:mm aa"
      inline
    />
    <View style={styles.buttons}>
      <Button 
        title="Confirm" 
        onPress={onConfirm}
        primary
      />
      <Button 
        title="Cancel" 
        onPress={onClose}
      />
    </View>
  </View>

    
}

export default DateFilters


const styles = StyleSheet.create({
  dateFilterContainer: {
    width: 330,
  },
  rule: {
    height: 1,
    backgroundColor: '#F0F0F0',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    flexDirection: 'row',
    marginTop: 10,
  },
})
