import React from 'react'
import View from './../view'
import * as config from './../../../config'
import { useAppNotificationAndErrorsContext } from './../../../util/AppNotificationsAndErrors'
import { useSessionContext } from './../../../util/Session'

const { useEffect, useState } = React

const MagicLinkContainer = props => {

  const { route } = props
  const { pushNotification } = useAppNotificationAndErrorsContext()
  const { setAuthToken } = useSessionContext()
  const [isInvalid, setIsInvalid] = useState(false)
  const code = route.params.code

  useEffect(() => {
    var magicLinkUrl = config.magicLinkUrl + '/' + code
    return fetch(magicLinkUrl).then((response) => {
      return response.json().then((result) => {
        if (response.status != 200) {
          var error = new Error(result?.message || 'Error using magic link.')
          console.error(error)
          pushNotification({ 
            type: 'error', 
            message: error.message,
          })
          setIsInvalid(true)
        }
        var { jwt } = result
        setAuthToken(jwt)
      })
    })
  }, [])

  return <View 
    isInvalid={isInvalid}
  />
}

export default MagicLinkContainer
