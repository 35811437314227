import React from 'react'
import { StyleSheet, View } from 'react-native'
import Header from './../../../components/Header'
import Label from './../../../components/Label'
import TextInput from './../../../components/TextInput'
import NumberInput from './../../../components/NumberInput'
import Button from './../../../components/Button'
import SmallSquareButton from './../../../components/SmallSquareButton'
import Bin from './../../../assets/Bin'
import Add from './../../../assets/Add'

const ProfileView = props => {

  const { 
    apiKey,
    secretApiKey,
    onConfirm, 
    onCancel, 
    impersonateUrl,
    onImpersonateUrlChange,
  } = props

  return <>
    <Header>Profile</Header>
    <View style={styles.content}>
      <Label>Configuration</Label>

      <TextInput
        label="api key"
        containerStyle={styles.inputContainer}
        value={apiKey}
        disabled
      />

      <TextInput
        label="secret api key"
        containerStyle={styles.inputContainer}
        value={secretApiKey}
        disabled
      />

      <TextInput
        label="impersonate url"
        containerStyle={styles.inputContainer}
        value={impersonateUrl}
        onChange={e => onImpersonateUrlChange(e.target.value)}
      />


      <View style={styles.buttons}>
        <Button 
          title="Confirm" 
          onPress={onConfirm}
          primary
        />
        <Button 
          title="Cancel" 
          onPress={onCancel}
        />
      </View>
    </View>
  </>
}

export default ProfileView

const styles = StyleSheet.create({
  content: {
    marginTop: 62,
    maxWidth: 466,
  },
  buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },
  inputContainer: {
    marginTop: 10,
  },
  largeInputContainer: {
    marginTop: 10,
    height: 300,
  },
  largeTextInput: {
    height: 300,
  },
})

