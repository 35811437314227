import React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'
import { useRequestsContext } from './../../../util/Requests'
import { useAppNotificationAndErrorsContext } from './../../../util/AppNotificationsAndErrors'

const { useEffect, useState } = React

const ProfileContainer = props => {

  const { route } = props

  
  const navigation = useNavigation()
  const { graphQLCall } = useRequestsContext()
  const { pushNotification } = useAppNotificationAndErrorsContext()
  const [apiKey, setApiKey] = useState('')
  const [secretApiKey, setSecretApiKey] = useState('')
  const [impersonateUrl, setImpersonateUrl] = useState('')

  

  useEffect(async () => {
    var response = await graphQLCall(
      `
        query GetUserSettings {
          me {
            apiKey
            secretApiKey
            impersonateUrl
          }
        }
      `,
      {}
    );
    const { success, result } = response
    if (!success)
      return
    var settings = result.data.me
    setApiKey(settings.apiKey || '')
    setSecretApiKey(settings.secretApiKey || '')
    setImpersonateUrl(settings.impersonateUrl || '')
  }, [])


  const onConfirm = async () => {

    //var isValid = validate()
    //if (!isValid) return { success: false, valid: false };

    var result = await graphQLCall(
      `
        mutation UpdateSettings($input: UpdateSettingsInput!) {
          updateSettings(input: $input){
            success
          }
        }
      `,
      {
        input: {
          impersonateUrl,
        },
      }
    );
    var { result: data, success } = result;
    if (!success) return result

    pushNotification({ 
      type: 'information', 
      message: 'Google ettings successfully updated.'
    })

    //navigation.navigate('CustomerList')
    return result;
  }

  const onCancel = () => {
    navigation.navigate('CustomerList')
  }

  return <View
    apiKey={apiKey} 
    secretApiKey={secretApiKey}
    onConfirm={onConfirm}
    onCancel={onCancel}
    impersonateUrl={impersonateUrl}
    onImpersonateUrlChange={setImpersonateUrl}
  />
}

export default ProfileContainer
