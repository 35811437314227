import React from 'react'
import View from './../view'

const { useRef } = React

const EventValuesContainer = props => {


  const onConfirm = () => {

  }

  const onCancel = () => {

  }

  return <View

  />
}

export default EventValuesContainer
