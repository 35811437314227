import React from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import Header from './../../../components/Header'
import Table from './../../../components/Table'
import Text from './../../../components/Text'
import DateFilters from './DateFilters'
import { safeNewDate, formatToLocalDateString } from './../../../util/date'
import { useModalContext } from './../../../util/Modal'
import EventJSONPopupContent from './EventJSONPopupContent'
import { countriesHash } from './../../../util/countryCodes'

const { useState } = React

const EventLogsView = props => {

  const {
    fromDate,
    onFromDateChange,
    toDate,
    onToDateChange,
    onEventSelect,
    events,
    onNextPage,
    hasNextPage,
    onPrevPage,
    onGoToPage,
    currentPageNo,
    title,
  } = props

  const { showModal } = useModalContext()



  const formatToRow = (dto) => {

    var datetime = formatToLocalDateString(safeNewDate(dto.timestamp))
    const showEventJson = () => {
      showModal(
        <EventJSONPopupContent
          eventJSON={JSON.stringify(dto.userEvent, null, 2)}
        />
      )
    }

    var countryData = countriesHash[dto.countryCode]

    return [
      dto.id.slice(0, 8),
      dto.userEvent.type,
      <Pressable onPress={showEventJson}>
        <Text>{JSON.stringify(dto.userEvent).slice(0, 20)}</Text>
      </Pressable>,
      <Pressable 
        style={styles.countryContainer}
        onPress={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {countryData ? 
          <Text title={countryData.name} style={styles.flagContainer}>
            <span title={countryData.name}>{countryData.emoji}</span>
          </Text> : 
          null}
        <Text>{dto.ip}</Text>
      </Pressable>,
      datetime.slice(0, -4),
    ]
  }

  return <>
    {title || <Header style={styles.header}>Event Logs</Header>}

    <Table
      header={
        [
          'event #id',
          'event type',
          'user event json',
          'ip',
          'date',
        ]
      }
      data={
        events.map(formatToRow)
      }
      filters={[
        null,
        null,
        null,
        null,
        {
          filterUI: <DateFilters
            fromDate={fromDate}
            onFromDateChange={onFromDateChange}
            toDate={toDate}
            onToDateChange={onToDateChange}
          />
        }
      ]}
      //getCellStyle={getCellStyle}
      //onEndReached={onEndReached}
      //renderHash={renderHash}
      //keyExtractor={keyExtractor}
      //style={style}
      onRowPress={onEventSelect}
      /*onRowPress={
        onRowPress
          ? (index) => {
              onRowPress(paginationRef.tableData[index])
            }
          : undefined
      }
      onRefresh={refreshData}
      ListEmptyComponent={
        (paginationRef.fetching === undefined || paginationRef.fetching) &&
        paginationRef.tableData.length == 0
          ? ListLoadingComponent
          : ListEmptyComponent
      }
      rowStyle={rowStyle}*/
      onNextPage={onNextPage}
      hasNextPage={hasNextPage}
      onPrevPage={onPrevPage}
      onGoToPage={onGoToPage}
      currentPageNo={currentPageNo}
    />
  </>
}

export default EventLogsView


const styles = StyleSheet.create({
  header: {
    marginBottom: 34,
  },
  countryContainer: {
    flexDirection: 'row',
    pointerEvents: 'none',
  },
  flagContainer: {
    paddingRight: 5,
  },
})