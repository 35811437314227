import React from 'react'
import { StyleSheet, View } from 'react-native'
import Header from './../../../components/Header'
import Label from './../../../components/Label'
import TextInput from './../../../components/TextInput'
import Button from './../../../components/Button'
import Text from './../../../components/Text'
import EventLogs from './../../EventLogs'
import SmallSquareButton from '../../../components/SmallSquareButton'
import EyeIcon from '../../../assets/Eye'

const ClientView = props => {

  const { 
    onConfirm, 
    onCancel, 
    name,
    onNameChange,
    company,
    onCompanyChange,
    email,
    onEmailChange,
    value,
    onValueChange,
    valueValidationError,
    userId,
    impersonateUrl,
    gclid,
    onGclidChange,
    gclidValidationError,
    latitude,
    longitude,
    country,
    region,
    city,
    funnelStageValue,
    onFunnelStageValueChange,
    funnelStageValueValidationError,
  } = props

  if (impersonateUrl){
    try {
      var urlObject = new window.URL(impersonateUrl)
      urlObject.searchParams.set('uid', userId)
      var finalUrl = urlObject.toString()
    } catch (error){
      console.warn(error)
    }
  }

  return <>
    <Header>Client</Header>
    <View style={styles.content}>
      <View style={styles.contentContainer}>
        <View style={styles.leftContainer}>
          <View style={styles.subHeaderContainer}>
            <Label>Client details</Label>
            {finalUrl ? <a href={finalUrl} target="_blank"><SmallSquareButton 
              style={styles.impersonateButton}
              // This doesn't work
              //title="Impersonate this user"
            >
              <EyeIcon />
            </SmallSquareButton></a> : null}
          </View>
          <TextInput 
            label="name"
            value={name}
            onChange={e => onNameChange(e.target.value)}
          />
          <TextInput 
            label="company"
            value={company}
            onChange={e => onCompanyChange(e.target.value)}
          />
          <TextInput 
            label="email"
            value={email}
            onChange={e => onEmailChange(e.target.value)}
          />
          <TextInput 
            label="value"
            value={value}
            onChange={e => onValueChange(e.target.value)}
            errorMessage={valueValidationError}
          />
          <TextInput 
            label="Funnel Stage (Int)"
            value={funnelStageValue}
            onChange={e => onFunnelStageValueChange(e.target.value)}
            errorMessage={funnelStageValueValidationError}
          />
          <ul>
            <li>
              <Text>Signed up: 200</Text>
            </li>
            <li>
              <Text>Free user: 300</Text>
            </li>
            <li>
              <Text>Pro user: 400</Text>
            </li>
          </ul>
          <br />
          <View style={styles.subHeaderContainer}>
            <Label>Tracking</Label>
          </View>
          <TextInput 
            label="gclid"
            value={gclid}
            onChange={e => onGclidChange(e.target.value)}
            errorMessage={gclidValidationError}
          />
        </View>
        <View style={styles.rightContainer}>
          {(longitude && latitude) ? <View style={styles.infoGroup}>
            <Label>Location</Label>
            <Text><a 
              href={`https://maps.google.com/?q=${latitude},${longitude}`}
              target="_blank"
            >Google maps link</a></Text>
          </View> : null}
          {country ? <View style={styles.infoGroup}>
            <Label>Country</Label>
            <Text>{country}</Text>
          </View> : null}
          {region ? <View style={styles.infoGroup}>
            <Label>Region</Label>
            <Text>{region}</Text>
          </View> : null}
          {city ? <View style={styles.infoGroup}>
            <Label>City</Label>
            <Text>{city}</Text>
          </View> : null}
        </View>
      </View>
      <View style={styles.buttons}>
        <Button 
          title="Confirm" 
          onPress={e => onConfirm(e.target.value)}
          primary
        />
        <Button 
          title="Cancel" 
          onPress={e => onCancel(e.target.value)}
        />
      </View>
    </View>
    <View style={styles.eventsContainer}>
      
      <EventLogs 
        title={<Text style={styles.subHeader}>Events</Text>} 
        userId={userId}
      />
    </View>
  </>
}

export default ClientView

const styles = StyleSheet.create({
  content: {
    marginTop: 62,
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',

  },
  buttons: {
    flexDirection: 'row',
    marginTop: 20,
  },
  subHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '22px',
  },
  eventsContainer: {
    marginTop: 20,
  },
  subHeaderContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  infoGroup: {
    marginBottom: 10,

  },
  impersonateButton: {
    marginLeft: 5,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 466,
    flex: 1,
    marginRight: 20,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 466,
    flex: 1,
  },
})

