import React from 'react'
import Svg, { Path, Circle } from 'react-native-svg'

const Person = props => {
  const { highlighted, ...otherProps } = props
  return <Svg 
    width="16" 
    height="28" 
    viewBox="0 0 16 28" 
    fill="none"
    {...otherProps}
  >
    <Path 
      d="M1 26V16.1844C1 15.9073 1.11497 15.6426 1.31749 15.4535L4.50662 12.4755C4.8126 12.1897 5.26418 12.1249 5.63823 12.3129L7.5509 13.2743C7.83346 13.4163 8.16654 13.4163 8.4491 13.2743L10.3618 12.3129C10.7358 12.1249 11.1874 12.1897 11.4934 12.4755L14.6825 15.4535C14.885 15.6426 15 15.9073 15 16.1844V26C15 26.5523 14.5523 27 14 27H2C1.44772 27 1 26.5523 1 26Z" 
      fill={highlighted ? "#FFB800" : undefined}
      stroke="black" 
      strokeWidth="2"
    />
    <Circle 
      cx="8" 
      cy="5" 
      r="4" 
      fill={highlighted ? "#FFB800" : undefined}
      stroke="black" 
      strokeWidth="2"
    />
  </Svg>
}

export default Person
