
import React from 'react'
import { StyleSheet, View } from 'react-native'
import Cell from './Cell'
import Text from './../Text'
import Funnel from '../../assets/Funnel'
import SmallCircleButton from '../SmallCircleButton'
import ContextPopup from '../ContextPopup'

const { useState, cloneElement } = React

const HeaderCell = ({ style, ...props }) => {
  

  const { data, filter, isLast, ...otherProps } = props
  console.log('HeaderCell#isLast', isLast)
  const [filtersOpen, setFiltersOpen ] = useState(false)

  const onOpenFilters = () => setFiltersOpen(true)

  const onCloseFilters = (e) => {
    setFiltersOpen(false)
  }

  //

  var cellContents = <View>
    <Text style={[styles.headerCellText]}>{data}</Text>
    <View style={styles.filterButtons}>
      
      {filter ? <SmallCircleButton onPress={onOpenFilters}>
        <Funnel />
      </SmallCircleButton> : null}
      
    </View>
    <View style={styles.bottomSection}>
      <View style={styles.contextMenuOuterContainer}>
        <View 
          style={[
            styles.contextMenuContainer,
            isLast ? styles.contextMenuContainerAlignRight : undefined,
          ]}
        >
          {filtersOpen ? <ContextPopup onClose={onCloseFilters}>
            <View style={styles.contextMenu}>
              {cloneElement(filter.filterUI, { onClose: onCloseFilters })}
            </View>
          </ContextPopup> : null}
        </View>
      </View>
    </View>
  </View>

  return <Cell
    style={[styles.headerCell, style]}
    data={cellContents}
    {...otherProps}
  />
}

export default HeaderCell

const styles = StyleSheet.create({

  headerCell: {
    paddingBottom: 0,
  },
  headerCellText: {
    fontSize: 16,
    color: '#5D6670',
    lineHeight: 16,
  },
  filterButtons: {
    marginTop: 9,
  },
  bottomSection: {
    paddingTop: 9,
  },
  contextMenuOuterContainer: {
    position: 'relative',
  },
  contextMenuContainer: {
    position: 'absolute',
    top: 1,
    

  },
  contextMenuContainerAlignRight: {
    right: 0,
  },
  contextMenu: {
    //filter: 'drop-shadow(4px 4px 4px rgba(237, 237, 237, 0.25))',
    boxShadow: 'rgb(237 237 237 / 35%) 2px 2px 6px 6px',
    borderRadius: 3,
    backgroundColor: 'white',
    userSelect: 'none',
    padding: 22,
  },

})