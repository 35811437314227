import React from 'react'
import {
  View,
} from 'react-native'
import Header from './../../../components/Header'
import Text from './../../../components/Text'
import TextInput from './../../../components/TextInput'
import Button from './../../../components/Button'
import styles from './styles'

const Margin = ({ style, ...props }) => (
  <View style={[{ margin: 10, width: '100%' }, style]}>{props.children}</View>
)

const LoginView = (props) => {

  const isMobile = false

  return (
    <View>
      <Header>Login</Header>
      <Margin>
        <Text>
          Sign in using email. Enter your email below and we&apos;ll send you a
          link you can tap to sign in.
        </Text>
      </Margin>
      <Margin
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={[
            styles.inputContainer,
            isMobile ? styles.inputContainerMobile : undefined,
          ]}
        >
          <TextInput
            label="email"
            onChangeText={props.onEmailUpdate}
            autoCapitalize="none"
            autoCompleteType="email"
            autoCorrect={false}
            containerStyle={
              isMobile ? { height: 80, width: '100%' } : { flex: 1 }
            }
          />
          <Button
            onPress={props.onSubmit}
            title="Go"
            primary
            style={[
              styles.goButton,
              isMobile
                ? {
                    backgroundColor: '#333639',
                    marginLeft: 0,
                    marginTop: 20,
                    width: 100,
                  }
                : undefined,
            ]}
            textStyle={[isMobile ? { color: '#ffffff' } : undefined]}
          />
        </View>
      </Margin>
    </View>
  )
}

export default LoginView
