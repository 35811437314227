import { 
  NavigationContainer, 
  createNavigatorFactory,
} from '@react-navigation/native'
import DesktopNavigator from './components/DesktopNavigator'
import ErrorBoundary from './components/ErrorBoundary'
import CustomerList from './pages/CustomerList'
import EventLogs from './pages/EventLogs'
import Client from './pages/Client'
import EventValues from './pages/EventValues'
import GoogleConversionTracking from './pages/GoogleConversionTracking'
import Profile from './pages/Profile'
import Login from './pages/Login'
import LoginVerification from './pages/LoginVerification'
import MagicLink from './pages/MagicLink'
import Session, { useSessionContext } from './util/Session'

const Stack = createNavigatorFactory(DesktopNavigator)()

const linkingConfig = {
  config: {
    screens: {
      MagicLink: {
        path: 'magic-link/:code',
      },
      Login: 'login',
      CustomerList: 'customers',
      EventLogs: 'events',
      Client: 'client/:id',
      GoogleConversionTracking: 'google-conversion-tracking',
    },
  }
}

function App() {

  const { authToken } = useSessionContext()

  return (
    <NavigationContainer 
      linking={linkingConfig}
      /*initialState={authToken ? 
        {
          routes: [
            {
              name: 'CustomerList',
              params: { gt: 0 },
            },
          ],
        }: 
        undefined}*/
    >
      <Stack.Navigator>
        {authToken ? <>
          <Stack.Screen name="CustomerList" component={CustomerList} />
          <Stack.Screen name="EventLogs" component={EventLogs} />
          <Stack.Screen name="Client" component={Client} />
          <Stack.Screen name="EventValues" component={EventValues} />
          <Stack.Screen name="GoogleConversionTracking" component={GoogleConversionTracking} />
          <Stack.Screen name="Profile" component={Profile} />
        </> : <>
          <Stack.Screen name="Login" component={Login} />
          <Stack.Screen name="LoginVerification" component={LoginVerification} />
          <Stack.Screen 
            name="MagicLink"
            component={MagicLink}
          />
        </>}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default function AppWrapper(){
  return <Session>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Session>
}