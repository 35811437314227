import React from 'react'
import Svg, { Path } from 'react-native-svg'

const DoubleArrow = props => {
  return <Svg 
    width="12" 
    height="11" 
    viewBox="0 0 12 11" 
    fill="none"
    {...props}
  >
    <Path 
      d="M5.6084 10.3047L1.6084 5.80469L5.6084 1.30469" 
      stroke="#8C959D" 
      strokeWidth="1.30432" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <Path 
      d="M10.8262 10.3047L6.82617 5.80469L10.8262 1.30469" 
      stroke="#8C959D" 
      strokeWidth="1.30432" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </Svg>

}

export default DoubleArrow
