import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

const { cloneElement } = React

const Button = props => {

  const { 
    selected, 
    children, 
    onPress,
    disabled,
  } = props

  var childProps = {}
  if (selected){
    childProps.style = styles.selectedChild
    childProps.fill = '#23B0FF'
  }
  if (disabled){
    childProps.style = styles.disabledChild
    childProps.fill = '#8C959D'
  }
  const child = cloneElement(children, childProps)
  return <Pressable
    style={[
      styles.container,
      selected ? styles.selectedContainer : null,
    ]}
    onPress={onPress}
  >
    {child}
  </Pressable>
}

export default Button

const styles = StyleSheet.create({
  container: {
    width: 26,
    height: 26,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  selectedContainer: {
    backgroundColor: '#EFF9FF',
   
    borderRadius: 3,
    
  },
  selectedChild: {
    color: '#23B0FF',
  },
  disabledChild: {
    color: '#8C959D',
  },
})
