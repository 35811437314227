import * as React from 'react'
import Svg, { Circle, G, Rect } from 'react-native-svg'

const CrossCircle = (props) => (
  <Svg width={26} height={26} viewBox="0 0 26 26" fill="none" {...props}>
    <G opacity={0.64}>
      <Rect
        x={17.0859}
        y={7.69629}
        width={1.44444}
        height={13}
        rx={0.722222}
        transform="rotate(45 17.0859 7.69629)"
        fill="white"
      />
      <Rect
        x={18.1064}
        y={16.8889}
        width={1.44444}
        height={13}
        rx={0.722222}
        transform="rotate(135 18.1064 16.8889)"
        fill="white"
      />
      
    </G>
  </Svg>
)

export default CrossCircle
