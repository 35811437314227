import React from 'react'
import Svg, { Path } from 'react-native-svg'

const Analytics = props => {
  const { highlighted, ...otherProps } = props

  return <Svg 
    width="20" 
    height="21" 
    viewBox="0 0 20 21" 
    fill="none" 
    {...otherProps}
  >
    <Path 
      d="M1 15.8166V19.4999M5.46875 12.4999V19.4999M9.9375 13.1353V19.4999M14.4063 11.5103V19.4999M18.875 7.1499V19.4999M1 11.25L7.625 5.25L11.2917 8.61042L18.875 1" 
      stroke="black" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <Path 
      d="M1 11.25L7.625 5.25L11.2917 8.61042L18.875 1" 
      stroke={highlighted ? "#FFB400" : "black"}
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </Svg>
}

export default Analytics
