import React from "react"

const { createContext, useContext, useState } = React

const Context = createContext({});
export const useSessionContext = () => useContext(Context)

const Session = (props) => {
  const { children, ...otherProps } = props;
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'))

  const child = React.cloneElement(children, otherProps);

  const clearAuthToken = () => {
    localStorage.clear()
    setAuthToken(null)
  }

  const onSetAuthToken = (jwt) => {
    localStorage.setItem('authToken', jwt)
    setAuthToken(jwt)
  }

  return (
    <Context.Provider
      value={{
        setAuthToken: onSetAuthToken,
        clearAuthToken,
        authToken,
      }}
    >
      {child}
    </Context.Provider>
  );
};

export default Session


