import * as React from 'react'
import {
  useNavigationBuilder,
  StackRouter,
} from '@react-navigation/native'
import * as config from './../../../config'
import BlockUI from './../../../util/BlockUI'
import Requests from './../../../util/Requests'
import AppNotificationsAndErrors from './../../../util/AppNotificationsAndErrors'
import Modal from './../../../util/Modal'
import View from '../view'

const { useState, useEffect } = React

/*
const maybeHandleMagicLink = (url) => {
  var path = url.slice(config.appUrl.length)
  var regex = /\/magic-link\/(\S+)$/i
  var match = path.match(regex)
  if (!match) return
  var [, code] = match

  var magicLinkUrl = config.magicLinkUrl + '/' + code
  
  setAuthenticating(true)
  return fetch(magicLinkUrl).then((response) => {
    return response.json().then((result) => {
      if (response.status != 200) {
        setAuthenticating(false)
        // eslint-disable-next-line
        if (__DEV__) {
          // eslint-disable-next-line
          console.error(
            new Error(result?.message || 'Error using magic link.')
          )
        }
        return
      }
      var { jwt } = result
      return AsyncStorage.setItem('authToken', jwt).then(() => {
        // At this point the authToke is set so when it renders
        // the child it will figure out the user is logged in
        setAuthenticating(false)
      })
    })
  })
}*/


const DesktopNavigatorContainer = (props) => {
  const {
    initialRouteName,
    children,
    screenOptions,
  } = props

  const { state, navigation, descriptors } = useNavigationBuilder(StackRouter, {
    children,
    screenOptions,
    initialRouteName,
  })

  const tabContent = descriptors[state.routes[state.index].key].render()
  const selectedRouteName = state.routes[state.index].name
  
  useEffect(() => {
    const authToken = localStorage.getItem('authToken')
    //if (!authToken)
    //  navigation.navigate('Login')
  }, [])

  useEffect(() => {

  }, [])

  return <AppNotificationsAndErrors>
    <BlockUI>
      <Modal>
        <Requests>
          <View 
            tabContent={tabContent}
            selectedRouteName={selectedRouteName}
          />
        </Requests>
      </Modal>
    </BlockUI>
  </AppNotificationsAndErrors>
}

export default DesktopNavigatorContainer
