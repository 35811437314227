import React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'
import { useRequestsContext } from './../../../util/Requests'

const { useState, useEffect, useRef } = React

const CustomerListContainer = props => {

  const { route } = props

  const { graphQLCall } = useRequestsContext()
  const [users, setUsers] = useState([])
  const navigation = useNavigation()
  const paginationRef = useRef({
    cursors: []
  }).current
  const [hasNextPage, setHasNextPage] = useState(false)
  const [afterCursor, setAfterCursor] = useState(undefined)
  const [greaterThanValue, setGreaterThanValue] = useState(
    route?.params?.gt || ''
  )

  const RECORDS_PER_PAGE = 50

  useEffect(async () => {
    var response = await graphQLCall(
      `
        query(
          $first: Int!
          $after: String
          $greaterThanValue: Int
        ) {
          users(
            first: $first, 
            after: $after,
            greaterThanValue: $greaterThanValue
          ){
            nodes {
              id
              email
              name
              company
              ip
              value
              createdAt
              countryCode
              lastEventTimestamp
            }
            totalCount
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `,
      {
        first: RECORDS_PER_PAGE,
        after: afterCursor,
        greaterThanValue: greaterThanValue !== '' ? Number(greaterThanValue) : undefined,
      }
    );
    const { success, result } = response
    if (!success)
      return

    const users = result.data.users.nodes
    paginationRef.cursors.push(result.data.users.pageInfo.endCursor)
    setUsers(users)
    setHasNextPage(result.data.users.pageInfo.hasNextPage)
  }, [afterCursor, greaterThanValue])

  useEffect(() => {
    // Ensure the URL is up to date
    navigation.setParams({ 'gt': greaterThanValue })
  }, [])

  const onCustomerSelect = index => {
    var customer = users[index]
    navigation.navigate('Client', {
      id: customer.id,
    })
  }

  const onNextPage = () => {
    if (!paginationRef.cursors.length)
      return
    var lastCursor = paginationRef.cursors[paginationRef.cursors.length - 1]
    setAfterCursor(lastCursor)
  }

  const onGoToPage = (pageNum) => {
    paginationRef.cursors = paginationRef.cursors.slice(0, (pageNum - 1))
    var lastCursor = paginationRef.cursors[paginationRef.cursors.length - 1]
    setAfterCursor(lastCursor)
  }

  const onGreaterThanValueChange = val => {
    paginationRef.cursors = []
    setGreaterThanValue(val)
    navigation.setParams({ 'gt': val })
  }

  return <View
    users={users}
    onCustomerSelect={onCustomerSelect}
    onNextPage={onNextPage}
    hasNextPage={hasNextPage}
    currentPageNo={paginationRef.cursors.length}
    onGoToPage={onGoToPage}
    onGreaterThanValueChange={onGreaterThanValueChange}
    greaterThanValue={greaterThanValue}
  />
}

export default CustomerListContainer
