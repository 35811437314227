import React from "react"
import { View, StyleSheet } from 'react-native'
import LoadingSpinner from "./../components/LoadingSpinner"

const { createContext, useContext, useState } = React;

const Context = createContext({});
export const useBlockUIContext = () => useContext(Context);

const BlockUI = (props) => {
  const { children, classes, ...otherProps } = props;
  const [showBlockUI, setShowBlockUI] = useState(false);

  const child = React.cloneElement(children, otherProps);

  const setBlockUI = (enabled) => {
    setShowBlockUI(enabled);
  };

  return (
    <Context.Provider
      value={{
        setBlockUI,
      }}
    >
      <View style={styles.container}>
        {child}
        {showBlockUI ? (
          <View style={styles.loadingContainer}>
            <LoadingSpinner large />
          </View>
        ) : null}
      </View>
    </Context.Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  loadingContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    backgroundColor: "grey",
  },
});

export default BlockUI
