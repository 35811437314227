import React from "react";
import { StyleSheet, View, Pressable } from 'react-native'
import CloseIcon from './../assets/CrossCircle'

const { createContext, useContext, useState } = React;

const Context = createContext({});
export const useModalContext = () => useContext(Context);

const Modal = (props) => {
  const { children, ...otherProps } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const child = React.cloneElement(children, otherProps);

  const showModal = (content) => {
    setModalContent(content);
    setModalVisible(true);
    return {
      close: closeModal,
    };
  };

  const closeModal = () => {
    setModalContent(null);
    setModalVisible(false);
  };

  return (
    <Context.Provider
      value={{
        showModal,
      }}
    >
      <>
        {child}
        {modalVisible ? (
          <View style={styles.modalContainer}>
            <View style={styles.overlay}></View>
            <View style={styles.boxContainer}>
              <Pressable style={styles.closeIconContainer} onPress={closeModal}>
                <CloseIcon style={styles.closeIcon} />
              </Pressable>
              <View style={styles.content}>{modalContent}</View>
            </View>
          </View>
        ) : null}
      </>
    </Context.Provider>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#000000",
    opacity: 0.3,
  },
  boxContainer: {
    width: 640,
    maxWidth: "90%",
    minHeight: 308,
    maxHeight: "90vh",
    backgroundColor: "white",
    zIndex: 99,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: 4,
    position: "relative",
    display: "flex",
  },
  closeIconContainer: {
    width: 20,
    height: 20,
    backgroundColor: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 20,
    right: 20,
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 20,
  },
  closeIcon: {
    color: "white",
    fill: "white",
  },
  content: {
    //display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
});

export default Modal
