import React from "react";
import { useBlockUIContext } from "./BlockUI";
import { useAppNotificationAndErrorsContext } from './AppNotificationsAndErrors'
import * as config from './../config'

const { createContext, useContext } = React;

const Context = createContext({});
export const useRequestsContext = () => useContext(Context);

const Requests = (props) => {
  const { children, classes, ...otherProps } = props;
  const { setBlockUI } = useBlockUIContext()
  const { pushNotification } = useAppNotificationAndErrorsContext()

  const child = React.cloneElement(children, otherProps);

  const callEndpoint = async function(params){
    const { 
      query, 
      variables,
      url,
    } = params
    setBlockUI(true);
    try {
      var response = await graphQLCall(query, variables, { url });
      if (response.status != 200) {
        setBlockUI(false);
        pushNotification({ 
          type: 'error', 
          message: 'Error calling graphQL endpoint.', 
        })
        return { success: false };
      }
      var result = await response.json();
    } catch (err) {
      setBlockUI(false);
      pushNotification({ type: 'error', message: err.message })
      throw err;
    }
    setBlockUI(false);

    var { errors } = result;
    if (errors) {
      pushNotification({ type: 'error', message: errors[0].message })
      return { success: false, result };
    }

    return {
      result,
      success: true,
    };
  }

  const callGraphQLLocal = async (query, variables) => {
    return callEndpoint({ 
      query, 
      variables, 
      url: config.graphQLUrl,
    })
  };

  const callGraphQLAuthLocal = async (query, variables) => {
    return callEndpoint({ 
      query, 
      variables, 
      url: config.authGraphQLUrl,
    })
  }

  return (
    <Context.Provider
      value={{
        graphQLCall: callGraphQLLocal,
        graphQLAuthCall: callGraphQLAuthLocal,
      }}
    >
      {child}
    </Context.Provider>
  );
};

export default Requests;



/**
 * Call the graphQL endpoint
 * @param {string} query
 * @param {object} variables
 */
export const graphQLCall = async (query, variables, options = {}) => {
  const { url = config.graphQLUrl } = options
  var headers = {};
  if (localStorage.getItem("authToken")) {
    headers["Authorization"] = "Bearer " + localStorage.getItem("authToken");
  }

  return fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({
      query: query,
      variables: variables,
    }),
  });
};
