import React, { useState } from 'react'
import * as config from './../../../config'
//import { Platform } from 'react-native'
import View from './../view'
import { useRequestsContext } from './../../../util/Requests'

const LoginContainer = (props) => {
  const { navigation } = props

  const [email, setEmail] = useState('')
  const { graphQLAuthCall } = useRequestsContext()

  const onSubmit = async () => {
    /*var redirectUrl = Constants.manifest.extra.appUrl + '/AuthLanding'
    if (Platform.OS == 'web') {
      // eslint-disable-next-line
      var mixpanel = require('mixpanel-browser')
      redirectUrl += '?mixpanelDistinctId=' + mixpanel.get_distinct_id()
    }*/

    var { success } = await graphQLAuthCall(
      `
        mutation ($input: SendMagicLinkInput!) {
          sendMagicLink(input: $input) {
            success
          }
        }
      `,
      {
        input: {
          email: email,
          baseUrl: config.appUrl,
        },
      }
    )
    if (!success) return
    navigation.navigate('LoginVerification')
  }

  return <View onEmailUpdate={setEmail} onSubmit={onSubmit} />
}

export default LoginContainer
