import React from 'react'
import { View, FlatList, Pressable, RefreshControl, StyleSheet, Platform } from 'react-native'
import Cell from './Cell'
import HeaderCell from './HeaderCell'
import Row from './Row'
import HeaderRow from './HeaderRow'
import Seperator from './Seperator'
import Pagination from '../Pagination'

const { useState, useCallback, useRef } = React

const Table = (props) => {
  const {
    onEndReached,
    keyExtractor,
    style,
    onRowPress,
    getCellStyle,
    onRefresh,
    ListEmptyComponent,
    rowStyle,
    filters,
    header,
    onNextPage,
    hasNextPage,
    onPrevPage,
    onGoToPage,
    currentPageNo,
    rowLinks,
  } = props

  const [refreshing, setRefreshing] = useState(false)

  const localOnRefresh = useCallback(() => {
    setRefreshing(true)
    Promise.resolve(onRefresh()).then(() => setRefreshing(false))
  }, [])

  const showPagination = !!onNextPage || hasNextPage || !!onPrevPage 
    || !!currentPageNo
    || !!onGoToPage

  return (
    <View style={[styles.table, style]}>
      {header ? (
        <HeaderRow
          data={header}
          getCellStyle={getCellStyle}
          rowIndex={null}
          filters={filters}
        />
      ) : null}
      <FlatList
        style={[styles.rowList]}
        data={props.data}
        renderItem={({ item, index }) => {

          if (rowLinks){
            var rowHref = rowLinks[index]
          }

          return (
            <Row
              rowIndex={index}
              data={item}
              style={[
                rowStyle,
                index % 2 == 1 ? styles.altRow : undefined,
                index == 0 ? styles.firstRow : undefined,
                index == props.data.length - 1 ? styles.lastRow : undefined,
                onRowPress ? styles.pressableRow : undefined,
              ]}
              getCellStyle={getCellStyle}
              onPress={onRowPress ? () => onRowPress(index) : undefined}
              rowHref={rowHref}
            />
          )
        }}
        ItemSeparatorComponent={Seperator}
        keyExtractor={keyExtractor}
        onEndReached={onEndReached}
        refreshControl={
          onRefresh ? (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={localOnRefresh}
            />
          ) : undefined
        }
        ListEmptyComponent={ListEmptyComponent}
      />
      {showPagination ? 
        <View style={styles.paginationContainer}>
          <Pagination 
            onNext={onNextPage}
            hasNextPage={hasNextPage}
            onPrev={onPrevPage}
            onGoToPage={onGoToPage}
            currentPageNo={currentPageNo}
          />
        </View> : 
        null}
    </View>
  )
}

export default Table



const styles = StyleSheet.create({
  table: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //borderWidth: 1,
    //borderStyle: 'solid',
    width: '100%',
    borderBottomWidth: 0,
  },
  rowList: {
    width: '100%',
    //borderBottomWidth: 1,
    //borderBottomColor: '#E0E8F0',
  },
  altRow: {},
  firstRow: {
    borderTopWidth: 1,
  },
  lastRow: {
    borderBottomWidth: 1,
  },
  pressableRow: {
    ...(Platform.OS == 'web'
      ? {
          cursor: 'pointer',
        }
      : undefined),
  },
  paginationContainer: {
    paddingTop: 5,
    paddingBottom: 5,
  },
})
