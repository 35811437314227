import React from 'react'
import Svg, { Path } from 'react-native-svg'

const BackArrow = ({ fill = "#212529", ...props }) => {
  return <Svg 
    width="6" 
    height="11" 
    viewBox="0 0 6 11" 
    fill="none"
    {...props}
  >
    <Path 
      d="M5 10L1 5.5L5 1" 
      stroke={fill}
      strokeWidth="1.30432" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </Svg>
}

export default BackArrow
