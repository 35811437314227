import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    maxWidth: 500,
    alignSelf: 'center',
    width: '100%',
  },
  inputContainer: {
    flexDirection: 'row',
  },
  inputContainerMobile: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  goButton: {
    height: 80,
    marginLeft: 10,
  },
  versionHeader: {
    color: '#676767',
    fontSize: 8,
    position: 'absolute',
    top: 10,
    left: 10,
  },
})

export default styles
