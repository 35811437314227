
import React from 'react'
import Svg, { Path } from 'react-native-svg'

const Exit = props => {
  const { highlighted, ...otherProps } = props

  return <Svg 
    width="12" 
    height="12" 
    viewBox="0 0 12 12" 
    fill="none" 
    {...otherProps}
  >
    <Path d="M6.71405 8.64236L6.71405 10.9994L1.00013 10.9994L1.00013 1L6.71405 1L6.71405 3.28557" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M9.64294 4.14453L11 6.00155L9.64294 7.71573" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M11 6.00146L3.8576 6.00146" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
}

export default Exit

