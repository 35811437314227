import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import Text from './../Text'
import CrossCircleIcon from './../../assets/CrossCircle'
import ExclamationIcon from './../../assets/Exclamation'

const Toast = ({ notificationData, onDismiss }) => {
  const { type, message } = notificationData
  return (
    <TouchableOpacity
      style={[
        styles.container,
        type == 'information' ? styles.informationContainer : undefined,
        { top: 10 },
      ]}
      onPress={onDismiss}
    >
      <View
        style={[
          styles.left,
          type == 'information' ? styles.informationLeft : undefined,
        ]}
      >
        <ExclamationIcon />
      </View>
      <View style={styles.right}>
        <View style={styles.rightInnerLeft}>
          <Text style={styles.textContent}>{message}</Text>
        </View>
        <View style={styles.rightInnerRight}>
          <CrossCircleIcon />
        </View>
      </View>
    </TouchableOpacity>
  )
}
export default Toast

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FE5157',
    flexDirection: 'row',
    borderRadius: 8,
    width: 320,
    marginTop: 10,
  },
  informationContainer: {
    backgroundColor: '#4C9EFF',
  },
  left: {
    width: 36,
    backgroundColor: '#FF7F83',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  informationLeft: {
    backgroundColor: '#83BCFF',
  },
  right: {
    flex: 1,
    paddingVertical: 20,
    paddingLeft: 7,
    flexDirection: 'row',
  },
  rightInnerLeft: {
    flex: 1,
  },
  rightInnerRight: {
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContent: {
    color: 'white',
    fontSize: 16,
  },
})
