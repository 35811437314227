import React from 'react'
import Svg, { Path } from 'react-native-svg'

const List = props => {
  const { highlighted, ...otherProps } = props

  return <Svg 
    //width="24" 
    //height="27" 
    viewBox="0 0 24 27" 
    width={22}
    fill="none"
    {...otherProps}
  >
    <Path 
      d="M9.11041 3.88983C9.11041 2.30508 10.4155 1 12.0002 1C13.585 1 14.8901 2.30508 14.8901 3.88983" 
      fill={highlighted ? "#FFB800" : undefined}
    />
    <Path 
      d="M9.11041 3.88983C9.11041 2.30508 10.4155 1 12.0002 1C13.585 1 14.8901 2.30508 14.8901 3.88983" 
      stroke="#232323" 
      strokeWidth="2" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <Path 
      d="M14.8898 3.89185H22.5339C22.8136 3.89185 23 4.07829 23 4.35795V24.7732C23 25.0529 22.8136 25.2393 22.5339 25.2393H1.4661C1.18644 25.2393 1 25.0529 1 24.7732V4.35795C1 4.07829 1.18644 3.89185 1.4661 3.89185H9.11017" 
      fill={highlighted ? "#FFB800" : undefined}
    />
    <Path 
      d="M14.8898 3.89185H22.5339C22.8136 3.89185 23 4.07829 23 4.35795V24.7732C23 25.0529 22.8136 25.2393 22.5339 25.2393H1.4661C1.18644 25.2393 1 25.0529 1 24.7732V4.35795C1 4.07829 1.18644 3.89185 1.4661 3.89185H9.11017" 
      stroke="#232323" 
      strokeWidth="2" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <Path 
      d="M6.07709 9.46179H17.9232" 
      stroke="#232323" 
      strokeWidth="2" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
    <Path 
      d="M6.07709 14.5393H17.9232" 
      stroke="#232323" 
      strokeWidth="2" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <Path 
      d="M6.07709 19.6155H17.9232" 
      stroke="#232323" 
      strokeWidth="2" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </Svg>
}

export default List