import React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'
import { useRequestsContext } from './../../../util/Requests'
import { useAppNotificationAndErrorsContext } from './../../../util/AppNotificationsAndErrors'

const { useEffect, useState } = React

const ClientContainer = props => {

  const { route } = props

  
  const navigation = useNavigation()
  const { graphQLCall } = useRequestsContext()
  const { pushNotification } = useAppNotificationAndErrorsContext()
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [value, setValue] = useState('')
  const [valueValidationError, setValueValidationError] = useState('')
  const [gclid, setGclid] = useState('')
  const [gclidValidationError, setGclidValidationError] = useState('')
  const [impersonateUrl, setImpersonateUrl] = useState('')
  const [otherUserProps, setOtherUserProps] = useState({})
  const [funnelStageValue, setFunnelStageValue] = useState('')
  const [funnelStageValueValidationError, setFunnelStageValueValidationError] = useState('')

  const userId = route.params.id
  

  useEffect(async () => {
    var response = await graphQLCall(
      `
        query GetUser(
          $id: ID, 
        ) {
          users(
            id: $id,
          ){
            nodes {
              id
              name
              company
              email
              ip
              value
              gclid
              latitude
              longitude
              region
              country
              city
              funnelStage
            }
          }
          me {
            impersonateUrl
          }
        }
      `,
      {
        id: userId,
      }
    );
    const { success, result } = response
    if (!success)
      return

    const users = result.data.users.nodes
    const user = users[0]
    setName(user.name || '')
    setCompany(user.company || '')
    setEmail(user.email || '')
    setValue(String(user.value / 100))
    setImpersonateUrl(result.data.me.impersonateUrl || '')
    setGclid(user.gclid || '')
    setFunnelStageValue(user.funnelStage ? String(user.funnelStage) : '')

    setOtherUserProps(user)
  }, [])

  const validate = () => {

    setValueValidationError('')
    setFunnelStageValueValidationError('')
    

    var valid = true;

    if (value) {
      let valueNum = Number(value);
      if (Number.isNaN(valueNum)) {
        setValueValidationError("Invalid.");
        valid = false;
      }
    }

    if (funnelStageValue) {
      let funnelStageValueNum = Number(funnelStageValue);
      if (Number.isNaN(funnelStageValueNum)) {
        setFunnelStageValueValidationError("Invalid.");
        valid = false;
      } else if (funnelStageValueNum < otherUserProps.funnelStage){
        setFunnelStageValueValidationError("Can't decrease funnel stage.");
        valid = false;
      }
    }

    return valid
  }

  const onConfirm = async () => {

    var isValid = validate()
    if (!isValid) return { success: false, valid: false };

    var result = await graphQLCall(
      `
        mutation UpdateUser($input: UpdateUserInput!) {
          updateUser(input: $input){
            success
          }
        }
      `,
      {
        input: {
          id: userId,
          name,
          company,
          email,
          // Convert it to cents
          value: Number(value) * 100,
          gclid,
          funnelStage: funnelStageValue ? Number(funnelStageValue) : null,
        },
      }
    );
    var { result: data, success } = result;
    if (!success) return result

    pushNotification({ 
      type: 'information', 
      message: 'Customer successfully updated.'
    })

    //navigation.navigate('CustomerList')
    return result;
  }

  const onCancel = () => {
    navigation.navigate('CustomerList')
  }

  return <View 
    {...otherUserProps}
    name={name}
    onNameChange={setName}
    company={company}
    onCompanyChange={setCompany}
    email={email}
    onEmailChange={setEmail}
    value={value}
    onValueChange={setValue}
    valueValidationError={valueValidationError}
    onConfirm={onConfirm}
    onCancel={onCancel}
    userId={userId}
    impersonateUrl={impersonateUrl}
    gclid={gclid}
    onGclidChange={setGclid}
    gclidValidationError={gclidValidationError}
    funnelStageValue={funnelStageValue}
    onFunnelStageValueChange={setFunnelStageValue}
    funnelStageValueValidationError={funnelStageValueValidationError}
  />
}

export default ClientContainer
