import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

const SmallCircleButton = props => {
  const { children, ...otherProps } = props

  return <Pressable style={styles.container} {...otherProps}>
    {children}
  </Pressable>
}

export default SmallCircleButton

const styles = StyleSheet.create({
  container: {
    borderRadius: '50%',
    width: 26,
    height: 26,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#D9DEE3',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

