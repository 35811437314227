import React from 'react'
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Navbar from './../../../components/Navbar'

const DesktopNavigatorView = props => {
  const {
    tabContent,
    selectedRouteName,
  } = props

  return <View style={styles.container}>
    {[
      'Login', 
      'LoginVerification',
      'MagicLink',
    ].includes(selectedRouteName) == false ? <Navbar 
      style={styles.navbar} 
      selectedRouteName={selectedRouteName}
    /> : null}
    <View style={styles.content}>
      {tabContent}
      <StatusBar style="auto" />
    </View>
  </View>
}

export default DesktopNavigatorView


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    maxHeight: '100vh',
  },
  content: {
    flex: 1,
    paddingVertical: 56,
    paddingHorizontal: 56,
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#fcfdff',
  },
  navbar: {

  },
});
