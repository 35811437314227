import React from 'react'
import {
  TextInput as TextInputNative,
  StyleSheet,
  Platform,
  Pressable,
  View,
} from 'react-native'
import Text from './../Text'

const { useRef, Fragment } = React

const styles = StyleSheet.create({
  input: {
    fontFamily: 'Roboto_400Regular',
    color: '#212529',
    fontSize: 18,
    lineHeight: 24,
  },
  container: {
    borderRadius: 6,
    //backgroundColor: '#3C3A3A',
    backgroundColor: 'white',
    //borderColor: '#989898',
    borderColor: '#E0E8F0',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 8,
    paddingBottom: 4,
  },
  containerDisabled: {
    backgroundColor: '#f8f8f8',
  },
  label: {
    color: '#B4BDC6',
    fontSize: 16,
    marginBottom: 10,
    ...(Platform.OS == 'web'
      ? {
          pointerEvents: 'none',
          userSelect: 'none',
        }
      : undefined),
  },
  error: {
    color: 'red',
  },
})

const TextInput = (props) => {
  const {
    style,
    containerStyle,
    label,
    errorMessage,
    disabled,
    inputRef: inputRefProp,
    onPress: parentOnPress,
    ...otherProps
  } = props

  var inputRef = inputRefProp || useRef()
  const onPress = () => {
    if (parentOnPress) return parentOnPress()
    if (inputRef && inputRef.current) inputRef.current.focus()
  }

  return (
    <Fragment>
      <View
        style={[
          styles.container,
          disabled ? styles.containerDisabled : undefined,
          containerStyle,
        ]}
        onPress={onPress}
      >
        {label ? (
          <Pressable onPress={onPress} tabIndex={-1}>
            <Text
              style={[
                styles.label,
                disabled ? styles.labelDisabled : undefined,
              ]}
            >
              {label}
            </Text>
          </Pressable>
        ) : null}
        <TextInputNative
          style={[
            styles.input,
            disabled ? styles.inputDisabled : undefined,
            style,
          ]}
          placeholderTextColor="#B4BDC6"
          ref={inputRef}
          editable={!!parentOnPress || !disabled}
          onFocus={() => {
            if (parentOnPress) parentOnPress()
          }}
          {...otherProps}
        />
      </View>
      {errorMessage ? <Text style={styles.error}>{errorMessage}</Text> : null}
    </Fragment>
  )
}

export default TextInput
