import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  digitInput: {
    width: 20,
    height: 45,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  digitContainer: {
    marginLeft: 5,
    marginRight: 5,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
})

export default styles
