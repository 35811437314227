import React from 'react'
import { View, StyleSheet } from 'react-native'

const Seperator = () => <View style={styles.seperator} />

const styles = StyleSheet.create({
  seperator: {
    height: 1,
    backgroundColor: '#E0E8F0',
  },
})

export default Seperator