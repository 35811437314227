import React from 'react'
import { StyleSheet, Pressable } from 'react-native'
import { useHover } from 'react-native-web-hooks'
import Row from './Row'
import HeaderCell from './HeaderCell'

const { useState, useCallback, useRef } = React

const HeaderRow = (props) => {

  var {
    rowIndex,
    getCellStyle = function () {},
    onPress,
    data,
    style,
    filters,
  } = props
  const ref = useRef(null)

  const isHovered = useHover(ref)

  const isMobile = false

  return (
    <Pressable
      style={[
        styles.row,
        style,
      ]}
      onPress={onPress}
      ref={ref}
    >
      {data.map((cellData, index) => (
        <HeaderCell
          key={JSON.stringify(cellData)}
          data={cellData}
          style={getCellStyle({ rowIndex, cellIndex: index })}
          filter={filters ? filters[index] : undefined}
          isLast={index == (data.length - 1)}
        />
      ))}
    </Pressable>
  )
}

export default HeaderRow

const styles = StyleSheet.create({

  row: {
    //flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20,

    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E8F0',
    backgroundColor: '#F4F8FB',
    borderTopWidth: 1,
    paddingTop: 14,
    paddingBottom: 0,
    zIndex: 10,
  }
})