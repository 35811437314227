import React from 'react'
import { StyleSheet, View } from 'react-native'
import Header from './../../../components/Header'
import Label from './../../../components/Label'
import TextInput from './../../../components/TextInput'
import NumberInput from './../../../components/NumberInput'
import Button from './../../../components/Button'
import SmallSquareButton from './../../../components/SmallSquareButton'
import Bin from './../../../assets/Bin'
import Add from './../../../assets/Add'

const GoogleConversionTrackingView = props => {

  const { 
    serviceAccountEmail,
    onServiceAccountEmailChange,
    serviceAccountPrivateKey,
    onServiceAccountPrivateKeyChange,
    conversionSpreadsheetId,
    onConversionSpreadsheetIdChange,
    adjustmentSpreadsheetId,
    onAdjustmentSpreadsheetIdChange,
    onConfirm, 
    onCancel, 
    conversionName,
    onConversionNameChange,
  } = props

  return <>
    <Header>Google Conversion Tracking</Header>
    <View style={styles.content}>
      <Label>Google service account</Label>

      <TextInput
        label="service account email"
        containerStyle={styles.inputContainer}
        value={serviceAccountEmail}
        onChange={e => onServiceAccountEmailChange(e.target.value)}
      />

      <TextInput
        label="service account private key"
        multiline
        containerStyle={styles.largeInputContainer}
        value={serviceAccountPrivateKey}
        onChange={e => onServiceAccountPrivateKeyChange(e.target.value)}
        style={styles.largeTextInput}
      />

      <Label style={{marginTop: 30}}>Sheet IDs</Label>

      <TextInput
        label="conversion spreadshet ID"
        containerStyle={styles.inputContainer}
        value={conversionSpreadsheetId}
        onChange={e => onConversionSpreadsheetIdChange(e.target.value)}
      />

      <TextInput
        label="adjustment spreadshet ID"
        containerStyle={styles.inputContainer}
        value={adjustmentSpreadsheetId}
        onChange={e => onAdjustmentSpreadsheetIdChange(e.target.value)}
      />

      <Label style={{marginTop: 30}}>Conversion</Label>

      <TextInput
        label="Conversion Name"
        containerStyle={styles.inputContainer}
        value={conversionName}
        onChange={e => onConversionNameChange(e.target.value)}
      />

      <View style={styles.buttons}>
        <Button 
          title="Confirm" 
          onPress={onConfirm}
          primary
        />
        <Button 
          title="Cancel" 
          onPress={onCancel}
        />
      </View>
    </View>
  </>
}

export default GoogleConversionTrackingView

const styles = StyleSheet.create({
  content: {
    marginTop: 62,
    maxWidth: 466,
  },
  buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },
  inputContainer: {
    marginTop: 10,
  },
  largeInputContainer: {
    marginTop: 10,
    height: 300,
  },
  largeTextInput: {
    height: 300,
  },
})

