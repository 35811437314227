import React from 'react'
import Svg, { Path } from 'react-native-svg'

const Sliders = props => {
  return <Svg 
    width="12" 
    height="14" 
    viewBox="0 0 12 14" 
    fill="none"
    {...props}
  >
    <Path d="M3 6L3 1" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M3 13L3 11" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M3 10.4609C4.10457 10.4609 5 9.56551 5 8.46094C5 7.35637 4.10457 6.46094 3 6.46094C1.89543 6.46094 1 7.35637 1 8.46094C1 9.56551 1.89543 10.4609 3 10.4609Z" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M9 8L9 13" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M9 1L9 3" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M9 3.53906C7.89543 3.53906 7 4.43449 7 5.53906C7 6.64363 7.89543 7.53906 9 7.53906C10.1046 7.53906 11 6.64363 11 5.53906C11 4.43449 10.1046 3.53906 9 3.53906Z" stroke="#919696" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
}

export default Sliders
