import React from 'react'
import Svg, { Path } from 'react-native-svg'

const Home = props => {
  const { highlighted, ...otherProps } = props

  return <Svg 
    //width="20" 
    //height="20" 
    viewBox="0 0 20 20" 
    fill="none"
    width={22}
    {...otherProps}
  >
    <Path 
      d="M10.4291 1.35823C10.166 1.19633 9.83419 1.19633 9.57112 1.35823L1.48487 6.33438C1.2428 6.48335 1.09534 6.74724 1.09534 7.03148V18.0852C1.09534 18.5373 1.4618 18.9038 1.91386 18.9038H6.44165C6.89371 18.9038 7.26017 18.5373 7.26017 18.0852V14.2424C7.26017 13.7904 7.62664 13.4239 8.07869 13.4239H11.9215C12.3736 13.4239 12.74 13.7904 12.74 14.2424V18.0852C12.74 18.5373 13.1065 18.9038 13.5585 18.9038H18.0863C18.5384 18.9038 18.9049 18.5373 18.9049 18.0852V7.03148C18.9049 6.74724 18.7574 6.48335 18.5153 6.33438L10.4291 1.35823Z" 
      fill={highlighted ? "#FFB400" : undefined}
      stroke="black" 
      strokeWidth="2" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </Svg>
}

export default Home

