import React from 'react'
import { StyleSheet, View } from 'react-native'
import Header from './../../../components/Header'
import Label from './../../../components/Label'
import TextInput from './../../../components/TextInput'
import NumberInput from './../../../components/NumberInput'
import Button from './../../../components/Button'
import SmallSquareButton from './../../../components/SmallSquareButton'
import Bin from './../../../assets/Bin'
import Add from './../../../assets/Add'
import Text from './../../../components/Text'

const EventValuesView = props => {

  const { onConfirm, onCancel } = props

  return <>
    <Header>Event Values</Header>
    <View style={styles.content}>
      <Text>Coming soon.</Text>
    </View>
  </>

  return <>
    <Header>Event Values</Header>
    <View style={styles.content}>

      <View style={styles.headerRow}>
        <View style={styles.eventTypeContainer}>
          <Label>Event type</Label>  
        </View>
        <View style={styles.valueContainer}>
          <Label>Value</Label>
        </View>
        <View style={styles.actionsContainer}>
          
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.eventTypeContainer}>
          <TextInput 
            containerStyle={styles.inputContainer}
            style={styles.input}
          />  
        </View>
        <View style={styles.valueContainer}>
          <NumberInput 
            containerStyle={styles.inputContainer}
            style={styles.input}
          />
        </View>
        <View style={styles.actionsContainer}>
          <SmallSquareButton>
            <Bin />
          </SmallSquareButton>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.eventTypeContainer}>
          <TextInput 
            containerStyle={styles.inputContainer}
            style={styles.input}
          />  
        </View>
        <View style={styles.valueContainer}>
          <NumberInput 
            containerStyle={styles.inputContainer}
            style={styles.input}
          />
        </View>
        <View style={styles.actionsContainer}>
          <SmallSquareButton>
            <Bin />
          </SmallSquareButton>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.eventTypeContainer}></View>
        <View style={styles.newButtonContainer}>
          <SmallSquareButton>
            <Add />
          </SmallSquareButton>
        </View>
        <View style={styles.actionsContainer}>
          <View style={styles.spacer}></View>
        </View>
      </View>

      <View style={styles.buttons}>
        <Button 
          title="Confirm" 
          onPress={onConfirm}
          primary
        />
        <Button 
          title="Cancel" 
          onPress={onCancel}
        />
      </View>
    </View>
  </>
}

export default EventValuesView

const styles = StyleSheet.create({
  content: {
    marginTop: 62,
    maxWidth: 466,
  },
  buttons: {
    flexDirection: 'row',
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    //paddingVertical: 10,
  },
  headerRow: {
    flexDirection: 'row',
    paddingBottom: 10,
  },
  eventTypeContainer: {
    flex: 375 / 466,
    justifyContent: 'center',
  },
  valueContainer: {
    flex: 112 / 466,
    paddingLeft: 12,
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  newButtonContainer: {
    flex: 112 / 466,
    paddingLeft: 12,
    paddingTop: 9,
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  actionsContainer: {
    justifyContent: 'center',
    paddingLeft: 20,
  },
  inputContainer: {
    height: 57,
    borderRadius: 0,
  },
  input: {
    height: '100%',
  },
  spacer: {
    width: 34,
  },
})

