import React from 'react'
import { Text as NativeText, StyleSheet } from 'react-native'
import { useFonts, Roboto_400Regular, Roboto_500Medium } from '@expo-google-fonts/roboto';

const Text = props => {

  let [fontsLoaded] = useFonts({
    Roboto_400Regular,
    Roboto_500Medium,
  });

  return <NativeText
    style={styles.text}
    {...props}
  />
}

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Roboto_400Regular',
    color: '#1A1E1E',
  },
})

export default Text
