import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const Eye = props => {
  return <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M8 11.6934C11.3137 11.6934 14 9.87498 14 7.63185C14 5.38872 11.3137 3.57031 8 3.57031C4.68629 3.57031 2 5.38872 2 7.63185C2 9.87498 4.68629 11.6934 8 11.6934Z" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M8.00108 9.06076C8.79127 9.06076 9.43185 8.42018 9.43185 7.62999C9.43185 6.8398 8.79127 6.19922 8.00108 6.19922C7.21089 6.19922 6.57031 6.8398 6.57031 7.62999C6.57031 8.42018 7.21089 9.06076 8.00108 9.06076Z" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M8 3.52308V2" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M3.75325 4.67512L2.46094 3.38281" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M12.2461 4.72344L13.3999 3.52344" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M3.70709 10.7695L2.46094 12.0618" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M8 11.6914V13.2145" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M12.2461 10.5859L13.5846 11.9244" stroke="#FFB800" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>

}

export default Eye
