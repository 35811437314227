import React from 'react'
import { View, StyleSheet } from 'react-native'
import NumberInput from '../../../components/NumberInput'
import Button from './../../../components/Button'

const { useState } = React

const ValueFilter = props => {

  const { 
    greaterThanValue: greaterThanValueProp, 
    onGreaterThanValueChange,
    onClose,
  } = props

  const [greaterThanValue, setGreaterThanValue] = useState(greaterThanValueProp)

  const onConfirm = () => {
    onGreaterThanValueChange(greaterThanValue)
    onClose()
  }

  return <View style={styles.container}>
    <NumberInput 
      label="greater than"
      value={greaterThanValue}
      onChangeText={setGreaterThanValue}
    />
    <View style={styles.buttons}>
      <Button 
        title="Confirm" 
        onPress={onConfirm}
        primary
      />
      <Button 
        title="Cancel" 
        onPress={onClose}
      />
    </View>
  </View>
}

export default ValueFilter

const styles = StyleSheet.create({
  container: {
    width: 330,
  },
  buttons: {
    flexDirection: 'row',
    marginTop: 10,
  },
})
