import React from 'react'
import { useNavigation } from '@react-navigation/native'
import View from './../view'
import { useRequestsContext } from './../../../util/Requests'
import { useAppNotificationAndErrorsContext } from './../../../util/AppNotificationsAndErrors'

const { useEffect, useState } = React

const GoogleConversionTrackingContainer = props => {

  const { route } = props

  
  const navigation = useNavigation()
  const { graphQLCall } = useRequestsContext()
  const { pushNotification } = useAppNotificationAndErrorsContext()
  const [serviceAccountEmail, setServiceAccountEmail] = useState('')
  const [serviceAccountPrivateKey, setServiceAccountPrivateKey] = useState('')
  const [conversionSpreadsheetId, setConversionSpreadsheetId] = useState('')
  const [adjustmentSpreadsheetId, setAdjustmentSpreadsheetId] = useState('')
  const [conversionName, setConversionName] = useState('')

  useEffect(async () => {
    var response = await graphQLCall(
      `
        query GetGoogleConversionTrackingSettings {
          me {
            googleConversionTrackingSettings {
              serviceAccountEmail
              serviceAccountPrivateKey
              conversionSpreadsheetId
              adjustmentSpreadsheetId
              conversionName
            }
          }
        }
      `,
      {}
    );
    const { success, result } = response
    if (!success)
      return
    var settings = result.data.me.googleConversionTrackingSettings
    setServiceAccountEmail(settings.serviceAccountEmail || '')
    setServiceAccountPrivateKey(settings.serviceAccountPrivateKey || '')
    setConversionSpreadsheetId(settings.conversionSpreadsheetId || '')
    setAdjustmentSpreadsheetId(settings.adjustmentSpreadsheetId || '')
    setConversionName(settings.conversionName || '')
  }, [])

  const validate = () => {
    var valid = true;
    return valid
  }

  const onConfirm = async () => {

    var isValid = validate()
    if (!isValid) return { success: false, valid: false };

    var result = await graphQLCall(
      `
        mutation UpdateGoogleConversionTrackingSettings($input: UpdateGoogleConversionTrackingInput!) {
          updateGoogleConversionTracking(input: $input){
            success
          }
        }
      `,
      {
        input: {
          serviceAccountEmail,
          serviceAccountPrivateKey,
          conversionSpreadsheetId,
          adjustmentSpreadsheetId,
          conversionName,
        },
      }
    );
    var { result: data, success } = result;
    if (!success) return result

    pushNotification({ 
      type: 'information', 
      message: 'Google conversion tracking settings successfully updated.'
    })

    //navigation.navigate('CustomerList')
    return result;
  }

  const onCancel = () => {
    navigation.navigate('CustomerList')
  }

  return <View
    serviceAccountEmail={serviceAccountEmail} 
    onServiceAccountEmailChange={setServiceAccountEmail}
    serviceAccountPrivateKey={serviceAccountPrivateKey} 
    onServiceAccountPrivateKeyChange={setServiceAccountPrivateKey}
    conversionSpreadsheetId={conversionSpreadsheetId} 
    onConversionSpreadsheetIdChange={setConversionSpreadsheetId}
    adjustmentSpreadsheetId={adjustmentSpreadsheetId} 
    onAdjustmentSpreadsheetIdChange={setAdjustmentSpreadsheetId}
    onConfirm={onConfirm}
    onCancel={onCancel}
    conversionName={conversionName}
    onConversionNameChange={setConversionName}
  />
}

export default GoogleConversionTrackingContainer
