import React from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import Text from './../Text'

const SubMenu = props => {
  const { style, children } = props
  return <View 
    style={[styles.subMenu, style]}
  >
    {children}
  </View>
}

export default SubMenu

export const SubMenuItem = props => {
  const { style, icon, children, onPress } = props
  return <Pressable 
    style={[styles.subMenuItem, style]}
    onPress={onPress}
  >
    <View style={styles.iconContainer}>
      {icon}
    </View>
    <View style={styles.content}>
      {children}
    </View>
  </Pressable>
}

const styles = StyleSheet.create({
  subMenu: {
    backgroundColor: 'white',
    paddingHorizontal: 13,
    paddingLeft: 0,
    paddingVertical: 17,
    width: 375,
    borderColor: '#E0E8F0',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    zIndex: 99,
  }, 
  subMenuItem: {
    flexDirection: 'row',
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  content: {
    flex: 1,
    fontSize: 16,
    lineHeight: '22px',
  },
  iconContainer: {
    paddingLeft: 17,
    paddingRight: 17,
  },
})
