/**
 * Formats a phone number into
 * DDDD DDD DDD
 */
export const formatPhoneNumber = function (phone) {
  phone = phone.replace(/\s/g, '')
  var lastPart = phone.slice(-6)
  phone = phone.slice(0, -lastPart.length)
  var formattedPhone = ''
  while (lastPart.length) {
    formattedPhone = ` ${lastPart.slice(-3)}${formattedPhone}`
    lastPart = lastPart.slice(0, -3)
  }
  return phone + formattedPhone
}

/**
 * Get the filename from the full path.
 */
export const getFilenameFromFullPath = function (filepath) {
  if (typeof filepath !== 'string') return ''
  var index = filepath.lastIndexOf('/')
  return filepath.slice(index + 1)
}

export const getFilenameFromWebUpload = (fileUri) => {
  const filename = Math.random().toString(36).substring(7);
  const filetype = fileUri.match(/[^:/]\w+(?=;|,)/)[0];
  return `${filename}.${filetype}`
}

/**
 * Converts a string date of format yyyy-mm-dd into a readable date string.
 * e.g. `2020-12-25` becomes `25th December 2020`.
 * @param {string} str
 * @returns {string}
 */
export const convertDateToReadableDate = function (str) {
  const regex = /(\d{4})-(\d{2})-(\d{2})/
  var match = str.match(regex)
  if (!match) throw new Error('Invalid date string.')
  var [, year, month, date] = match

  return `${getDescriptiveDate(Number(date))} ${getMonthString(Number(month) - 1)} ${year}`
}

/**
 * Converts a string date of format yyyy-mm-dd into a readable month string.
 * e.g. `2020-12-25` becomes `December 2020`.
 * @param {string} str
 * @returns {string}
 */
export const convertDateToReadableMonth = function (str) {
  const regex = /(\d{4})-(\d{2})-(\d{2})/
  var match = str.match(regex)
  if (!match) throw new Error('Invalid date string.')
  var [, year, month] = match
  return `${getMonthString(Number(month) - 1)} ${year}`
}

/**
 * Gets the month string of a date e.e. January, February, March
 * @param {number} 0 to 11
 * @returns {string}
 */
export const getMonthString = (monthInt) => {
  switch (monthInt) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
    default:
      throw new Error('Invalid month')
  }
}

export const getShortMonthString = (monthInt) => {
  switch (monthInt) {
    case 0:
      return 'Jan'
    case 1:
      return 'Feb'
    case 2:
      return 'Mar'
    case 3:
      return 'Apr'
    case 4:
      return 'May'
    case 5:
      return 'Jun'
    case 6:
      return 'Jul'
    case 7:
      return 'Aug'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dec'
    default:
      throw new Error('Invalid month')
  }
}

/**
 * Gets the date with 'st', 'th', etc appended.
 * @param {number} 1 to 31
 * @returns {string}
 */
export const getDescriptiveDate = (date) => {
  switch (date) {
    case 1:
    case 21:
    case 31:
      return `${date}st`
    case 2:
    case 22:
      return `${date}nd`
    case 3:
    case 23:
      return `${date}rd`
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
      return `${date}th`
    default:
      throw new Error('Invalid date')
  }
}

/**
 * Converts a date to a string of yyyy-mm-dd
 */
export const convertToShortDate = function (date) {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`
}

export const padZero = function (str) {
  str = String(str)
  while (str.length < 2) str = `0${str}`
  return str
}

/**
 * Converts a date object to the structure.
 * DD/MM/YYYY
 */
export const convertDateToString = function (date) {
  date = new Date(date)
  if (isNaN(date)) throw new Error('Invalid date passed to convertDateToString.')
  return `${padZero(date.getDate())}/${padZero(date.getMonth() + 1)}/${date.getFullYear()}`
}

/**
 * Converts two dates now and the litter available date into a string
 * that roughly communicates how far away the litter date is.
 */
export const getAvailabilityNotice = function (props) {
  const {
    now,
    availableDate,
  } = props

  // Month
  const m = 365 / 12
  // Half month
  const hm = 365 / 12 / 2

  var days = (availableDate - now) / (1000 * 60 * 60 * 24)
  if (days < 1) return 'Available now'
  if (days < 4) return 'A few days wait'
  if (days <= 10) return 'Approx 1 week wait'
  if (days <= 17) return 'Approx 2 weeks wait'
  if (days <= 24) return 'Approx 3 weeks wait'
  if (days <= ((m * 1) + hm)) return 'Approx 1 month wait'
  if (days <= ((m * 2) + hm)) return 'Approx 2 month wait'
  if (days <= ((m * 3) + hm)) return 'Approx 3 month wait'
  if (days <= ((m * 4) + hm)) return 'Approx 4 month wait'
  if (days <= ((m * 5) + hm)) return 'Approx 5 month wait'
  if (days <= ((m * 6) + hm)) return 'Approx 6 month wait'
  if (days <= ((m * 7) + hm)) return 'Approx 7 month wait'
  if (days <= ((m * 8) + hm)) return 'Approx 8 month wait'
  if (days <= ((m * 9) + hm)) return 'Approx 9 month wait'
  if (days <= ((m * 10) + hm)) return 'Approx 10 month wait'
  if (days <= ((m * 11) + hm)) return 'Approx 11 month wait'
  if (days <= ((m * 12))) return 'Approx a year wait'

  return 'Over a year wait'
}

/**
 * Gets a string that represents a point in the past that's relevant to now.
 * i.e. if the point just happend it will just show the time, as today is
 * assumed. If it happend a few days ago, it'll just show the date as that
 * is specific enough. Used to convery when a chat message was sent.
 * @param {Date} props.now
 * @param {Date} props.then
 * @returns {string}
 */
export const getHumanDateString = function (props) {
  const { now, then } = props
  var diff = now - then
  if (diff < (1000 * 60 * 60 * 24)) return `${padZero(then.getHours())}:${padZero(then.getMinutes())}`
  if (diff < ((1000 * 60 * 60 * 24 * 365) * 11 / 12)) return `${getShortMonthString(then.getMonth())} ${getDescriptiveDate(then.getDate())}`
  return `${getShortMonthString(then.getMonth())} ${then.getFullYear()}`
}

/**
 * Get the readable age string. e.g. a puppy may be "a week old".
 * @param {Date} birthDate
 * @param {Date} now
 * @returns {string}
 */
export const getReadbleAgeString = function (birthDate, now) {
  const days = (now - birthDate) / (1000 * 60 * 60 * 24)
  const weeks = (now - birthDate) / (1000 * 60 * 60 * 24 * 7)
  const months = (now - birthDate) / (1000 * 60 * 60 * 24 * 30.41)
  const years = (now - birthDate) / (1000 * 60 * 60 * 24 * 365)
  if (days < 1) return 'just now'
  if (days < 2) return 'a day old'
  if (days < 7) return 'a few days old'
  if (weeks < 2) return 'a week old'
  if (weeks < 10) return `${Math.round(weeks)} weeks old`
  if (months < 12) return `${Math.round(months)} months old`
  if (years < 2) return '1 year old'
  return `${Math.round(years)} years old`
}

/**
 * Converts the price string "number1-number2"
 * to comma-separated dollars
 * @param {string} priceStr range as string e.g. 1000-2000
 * @returns {string} a formatted price e.g. $1,000 - $2,000
 */
export const formatPriceString = function (price) {
  var priceString = price.toString()

  const priceRange = priceString.split('-')
  const regex = new RegExp(/\B(?=(\d{3})+(?!\d))/, 'g')
  const prices = priceRange.map((item) => {
    const res = item.replace(regex, ',')
    return `$${res}`
  })

  return prices.join(' - ')
}

/**
 * Converts a list of strings from foo,bar,cat to foo, bar and cat.
 */
export const describeListInEnglish = function (listStr) {
  if (!listStr.length) return ''
  if (listStr.length == 1) return listStr[0]
  return `${listStr.slice(0, -1).join(', ')} and ${listStr[listStr.length - 1]}`
}

/**
 * Format a string into a currency string.
 */
export const formatToReadableCurrency = (str, options = {}) => {
  const { padDecimal = true } = options

  if (str == null) str = ''
  str = String(str).replace(/[^-0-9.]/g, '')

  var decimalIndex = str.indexOf('.')
  if (decimalIndex != -1) {
    var wholePart = str.slice(0, decimalIndex)
    var fractionalPart = str.slice(decimalIndex + 1)
    fractionalPart = fractionalPart.replace(/\./g, '')
    fractionalPart = fractionalPart.slice(0, 2)
  } else {
    var wholePart = str
    var fractionalPart = ''
  }

  if (wholePart.startsWith('-')) {
    var isNegative = true
  } else {
    var isNegative = false
  }
  // Remove the negative sign
  wholePart = wholePart.replace(/[^0-9.]/g, '')

  // Add commas to whole number
  var i = 0
  var newWholeParts = []
  while (i < wholePart.length) {
    var start = Math.max(wholePart.length - i - 3, 0)
    var end = wholePart.length - i

    newWholeParts.splice(0, 0, wholePart.slice(start, end))

    i += 3
  }

  var newStr = newWholeParts.join(',')

  if (isNegative) newStr = `-${newStr}`

  var final = `$${newStr}`
  if (decimalIndex != -1) final += '.'
  if (fractionalPart) {
    if (padDecimal) {
      while (fractionalPart.length < 2) {
        fractionalPart += '0'
      }
    }
    final += fractionalPart
  }

  return final
}

export const formatToCurrency = (str) => {
  str = formatToReadableCurrency(str, { padDecimal: false })
  return str.replace(/[^-0-9.]/g, '')
}

export const toTitleCase = (str) => str.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
)
