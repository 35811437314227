import * as React from 'react'
import { View } from 'react-native'
import TextInput from '../TextInput'
import styles from './styles'

const { useRef } = React

/**
 * @param {Array<string>} props.value An array for each character of the
 * verification code
 */
const VerificationCodeInput = (props) => {
  const { onChangeValue = noop, value = [] } = props

  const digitInput1 = useRef(null)
  const digitInput2 = useRef(null)
  const digitInput3 = useRef(null)
  const digitInput4 = useRef(null)
  const digitInput5 = useRef(null)
  const digitInput6 = useRef(null)

  const moveToNext = (prevRef, index, curRef, nextRef) => (e) => {
    const val = e.nativeEvent.key
    if (val == 'Backspace') {
      if (prevRef && (value[index] == '' || value[index] == undefined)) {
        setTimeout(() => prevRef.current.focus(), 4)
        onChangeText(index - 1)('')
      }
    } else if (Number.isInteger(Number(val))) {
      if (nextRef) {
        // nextRef.current.focus()
        /* setTimeout(
          () => nextRef.current.focus(),
          4,
        ) */
      }
      // else curRef.current.blur()
    }
  }

  const onChangeText = (index, nextRef) => (char) => {
    var newValue = value.slice(0)
    newValue[index] = char
    onChangeValue(newValue)
    if (newValue && nextRef) nextRef.current.focus()
  }

  const onFirstInputChangeText = (str) => {
    if (str.length == 1) return onChangeText(0, digitInput2)(str)
    var chars = str.split('').slice(0, 6)
    onChangeValue(chars)
    var lastRef = [
      digitInput1,
      digitInput2,
      digitInput3,
      digitInput4,
      digitInput5,
      digitInput6,
    ][chars.length - 1]
    if (lastRef) lastRef.current.focus()
  }

  return (
    <View style={[styles.container]}>
      <TextInput
        inputRef={digitInput1}
        autoCapitalize="characters"
        style={[styles.digitInput]}
        onKeyPress={moveToNext(null, 0, digitInput1, digitInput2)}
        value={value[0]}
        onChangeText={onFirstInputChangeText}
      />
      <TextInput
        inputRef={digitInput2}
        autoCapitalize="characters"
        style={[styles.digitInput]}
        onKeyPress={moveToNext(digitInput1, 1, digitInput2, digitInput3)}
        value={value[1]}
        onChangeText={onChangeText(1, digitInput3)}
        maxLength={1}
        containerStyle={styles.digitContainer}
      />
      <TextInput
        inputRef={digitInput3}
        autoCapitalize="characters"
        style={[styles.digitInput]}
        onKeyPress={moveToNext(digitInput2, 2, digitInput3, digitInput4)}
        value={value[2]}
        onChangeText={onChangeText(2, digitInput4)}
        maxLength={1}
        containerStyle={styles.digitContainer}
      />
      <TextInput
        inputRef={digitInput4}
        autoCapitalize="characters"
        style={[styles.digitInput]}
        onKeyPress={moveToNext(digitInput3, 3, digitInput4, digitInput5)}
        value={value[3]}
        onChangeText={onChangeText(3, digitInput5)}
        maxLength={1}
        containerStyle={styles.digitContainer}
      />
      <TextInput
        inputRef={digitInput5}
        autoCapitalize="characters"
        style={[styles.digitInput]}
        onKeyPress={moveToNext(digitInput4, 4, digitInput5, digitInput6)}
        value={value[4]}
        onChangeText={onChangeText(4, digitInput6)}
        maxLength={1}
        containerStyle={styles.digitContainer}
      />
      <TextInput
        inputRef={digitInput6}
        autoCapitalize="characters"
        style={[styles.digitInput]}
        onKeyPress={moveToNext(digitInput5, 5, digitInput6, null)}
        value={value[5]}
        onChangeText={onChangeText(5)}
        maxLength={1}
        containerStyle={styles.digitContainer}
      />
    </View>
  )
}

export default VerificationCodeInput

function noop() {}
