import React from 'react'
import { Pressable, StyleSheet, Platform } from 'react-native'
import { useHover } from 'react-native-web-hooks'
import Cell from './Cell'

const { useRef } = React


const Row = (props) => {
  var {
    CellComponent = Cell,
    rowIndex,
    getCellStyle = function () {},
    onPress,
    data,
    style,
    rowHref,
  } = props
  const ref = useRef(null)

  const isHovered = useHover(ref)

  const isMobile = false

  var inner = <Pressable
    style={[
      styles.row,
      isMobile ? styles.rowMobile : styles.rowDesktop,
      onPress && isHovered ? styles.isHovered : undefined,
      style,
    ]}
    onPress={onPress}
    ref={ref}
  >
    {data.map((data, index) => (
      <CellComponent
        //key={JSON.stringify(data)}
        data={data}
        style={getCellStyle({ rowIndex, cellIndex: index })}
      />
    ))}
  </Pressable>

  if (rowHref && Platform.OS == 'web'){
    return <a href={rowHref} style={{textDecoration: 'none'}}>
      {inner}
    </a>
  }

  return inner

  
}

export default Row

const styles = StyleSheet.create({
  row: {
    //flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20,

    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E8F0',
  },
  rowMobile: {
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  rowDesktop: {
    backgroundColor: 'white',
  },
  isHovered: {
    backgroundColor: '#f7f7f7',
  },
})