import React from 'react'
import { View } from 'react-native'
import Toast from './Toast'
import styles from './styles'

const ToastNotifications = (props) => {
  const { notifications, onNotificationDismiss, style } = props
  return (
    <View style={[styles.container, style]}>
      {notifications.map((item, index) => (
        <Toast
          key={item.uniqueKey}
          notificationData={item}
          index={index}
          onDismiss={() => onNotificationDismiss({ item })}
        />
      ))}
    </View>
  )
}

export default ToastNotifications
